import { useCallback, useState, useMemo } from 'react';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { useDataLayerActions } from '@hooks';
import { ProductStars, Link } from '@snippets';
import { ColorVariantSelector } from './ColorVariantSelector';
import { ProductItemMedia } from './ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { QuickShop } from './QuickShop';

export function ProductItem({
  enabledColorNameOnHover,
  enabledColorSelector,
  enabledQuickShop,
  enabledStarRating,
  handle,
  index,
  isSearchResults,
  onClick,
  product: passedProduct,
  swatchesMap,
}) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { product: fetchedFullProduct } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });
  const { sendClickProductItemEvent } = useDataLayerActions();

  const initialProduct =
    fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);

  const [productFromColorSelector, setProductFromColorSelector] =
    useState(null);
  const [variantFromColorSelector, setVariantFromColorSelector] =
    useState(null);

  const selectedProduct = productFromColorSelector || initialProduct;

  const lowestPriceVariant = selectedProduct?.variants?.find(
    (variant) =>
      variant?.price === selectedProduct?.priceRange?.minVariantPrice?.amount
  );
  const selectedVariant =
    variantFromColorSelector ||
    lowestPriceVariant ||
    selectedProduct?.variants?.[0];

  const productUrl = selectedProduct
    ? `/products/${selectedProduct.handle}`
    : '';
  const color = selectedVariant?.selectedOptionsMap?.Color;
  const title = selectedProduct?.title;

  const { localized } = useLocalizedVariant({ variant: selectedVariant });

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: isSearchResults,
      listIndex: index,
      localized,
      product: selectedProduct,
      selectedVariant,
    });
    if (typeof onClick === 'function') onClick();
  }, [index, localized, selectedProduct?.id, selectedVariant?.id]);

  const option = useMemo(() => {
    if (!selectedProduct) return { name: '', values: [] };
    const options = selectedProduct.grouping?.isTransformed
      ? selectedProduct.grouping.options
      : selectedProduct.options;
    const _option = options.find(({ name }) => name !== 'Color');
    return {
      name: _option?.name,
      values: _option?.values || [],
    };
  }, [selectedProduct?.id]);
  const hasOneVariant = selectedProduct?.variants?.length === 1;
  const variantTitle =
    hasOneVariant && selectedProduct?.variants?.[0]?.title !== 'Default Title'
      ? selectedProduct?.variants?.[0]?.title
      : null;
  const variantTitleRange = !hasOneVariant
    ? `${option?.values?.[0]} - ${option?.values?.[option?.values?.length - 1]}`
    : null;

  return (
    <div
      data-comp={ProductItem.displayName}
      className="group flex h-full flex-col justify-between"
      ref={ref}
    >
      <div className="flex flex-col items-start">
        <Link
          aria-label={title}
          className="mb-3 w-full"
          href={productUrl}
          onClick={handleClick}
          tabIndex="-1"
        >
          <ProductItemMedia
            selectedProduct={selectedProduct}
            selectedVariant={selectedVariant}
          />
        </Link>

        {enabledStarRating && initialProduct?.legacyResourceId && (
          <div className="mb-1.5">
            <Link
              aria-label={`Reviews for ${title}`}
              href={productUrl}
              onClick={handleClick}
              tabIndex="-1"
            >
              <ProductStars product={initialProduct} color="var(--accent1)" />
            </Link>
          </div>
        )}

        <Link aria-label={title} href={productUrl} onClick={handleClick}>
          <h3 className="text-title-h5 min-h-[1.5rem] font-helvetica-condensed">
            {title}
          </h3>
        </Link>

        {variantTitle ||
          (variantTitleRange && (
            <p className="text-body text-mediumGray">
              {variantTitle || variantTitleRange}
            </p>
          ))}
        {color && <p className="text-body text-mediumGray">{color}</p>}

        <ProductItemPrice
          selectedVariant={selectedVariant}
          hasOneVariant={hasOneVariant}
        />

        {enabledColorSelector && (
          <ColorVariantSelector
            enabledColorNameOnHover={enabledColorNameOnHover}
            initialProduct={initialProduct}
            selectedVariant={selectedVariant}
            setProductFromColorSelector={setProductFromColorSelector}
            setVariantFromColorSelector={setVariantFromColorSelector}
            swatchesMap={swatchesMap}
          />
        )}
      </div>

      {enabledQuickShop && (
        <QuickShop
          enabledColorSelector={enabledColorSelector}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
        />
      )}
    </div>
  );
}

ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchResults: PropTypes.bool,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};
