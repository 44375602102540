import {
  COLORS,
  FLEX_POSITIONS,
  OBJECT_POSITIONS,
} from '../../settings/common';

const image = {
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      label: 'Image Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image (tablet/desktop)',
      name: 'imageDesktop',
      component: 'image',
    },
    {
      label: 'Image Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: OBJECT_POSITIONS.desktop,
    },
    {
      label: 'Image (mobile)',
      name: 'imageMobile',
      component: 'image',
    },
    {
      label: 'Image Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: OBJECT_POSITIONS.mobile,
    },
  ],
  defaultValue: {
    alt: 'Rack of green t-shirts',
    imageDesktop: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
    },
    positionDesktop: 'md:object-center',
    imageMobile: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
    },
    positionMobile: 'object-center',
  },
};

const text = {
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, subheading, color',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'textarea',
    },
    {
      label: 'Subheading',
      name: 'subheading',
      component: 'text',
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: COLORS,
    },
  ],
  defaultValue: {
    heading: 'Collection Heading',
    color: 'var(--white)',
  },
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description: 'Dark overlay, content position, content alignment',
  component: 'group',
  fields: [
    {
      label: 'Enable Dark Overlay',
      name: 'darkOverlay',
      component: 'toggle',
      description: 'Adds 20% opacity black overlay over media',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Content Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: FLEX_POSITIONS.desktop,
    },
    {
      label: 'Content Alignment (tablet/desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
        { label: 'Right', value: 'md:text-right md:items-end' },
      ],
    },
    {
      label: 'Max Content Width (tablet/desktop)',
      name: 'maxWidthDesktop',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'md:max-w-[22rem] lg:max-w-[28rem]' },
        { label: 'Medium', value: 'md:max-w-[30rem] lg:max-w-[38rem]' },
        { label: 'Wide', value: 'md:max-w-[38rem] lg:max-w-[48rem]' },
        { label: 'Full', value: 'md:max-w-full' },
      ],
    },
    {
      label: 'Content Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: FLEX_POSITIONS.mobile,
    },
    {
      label: 'Content Alignment (mobile)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
    },
    {
      label: 'Max Content Width (mobile)',
      name: 'maxWidthMobile',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'max-w-[16.5rem]' },
        { label: 'Medium', value: 'max-w-[22.5rem]' },
        { label: 'Wide', value: 'max-w-[28.5rem]' },
        { label: 'Full', value: 'max-w-full' },
      ],
    },
  ],
  defaultValue: {
    darkOverlay: false,
    alignmentDesktop: 'md:text-center md:items-center',
    positionDesktop: 'md:justify-center md:items-center',
    maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
    alignmentMobile: 'text-center items-center',
    positionMobile: 'justify-center items-center',
    maxWidthMobile: 'max-w-[22.5rem]',
  },
};

export function Schema({ collection }) {
  if (!collection) return null;

  return {
    category: 'Collection',
    label: 'Collection Hero',
    key: 'collection-hero',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/collection-hero-preview_e3870d31-1425-4aab-bc91-9bcc087a0ce8.jpg?v=1675795219',
    fields: [
      image,
      text,
      content,
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, full width, full bleed, height',
        fields: [
          {
            label: 'Background Color (if no image)',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Height (desktop)',
            name: 'heightDesktop',
            component: 'select',
            description: 'Sets desktop height of this section',
            options: [
              { label: 'Auto', value: 'lg:h-auto' },
              { label: '200px', value: 'lg:h-[12.5rem]' },
              { label: '300px', value: 'lg:h-[18.75rem]' },
              { label: '400px', value: 'lg:h-[25rem]' },
              { label: '500px', value: 'lg:h-[31.25rem]' },
            ],
          },
          {
            label: 'Height (tablet)',
            name: 'heightTablet',
            component: 'select',
            description: 'Sets tablet height of this section',
            options: [
              { label: 'Auto', value: 'md:h-auto' },
              { label: '200px', value: 'md:h-[12.5rem]' },
              { label: '300px', value: 'md:h-[18.75rem]' },
              { label: '400px', value: 'md:h-[25rem]' },
              { label: '500px', value: 'md:h-[31.25rem]' },
            ],
          },
          {
            label: 'Height (mobile)',
            name: 'heightMobile',
            component: 'select',
            description: 'Sets mobile height of this section',
            options: [
              { label: 'Auto', value: 'h-auto' },
              { label: '200px', value: 'h-[12.5rem]' },
              { label: '300px', value: 'h-[18.75rem]' },
              { label: '400px', value: 'h-[25rem]' },
              { label: '500px', value: 'h-[31.25rem]' },
            ],
          },
        ],
        defaultValue: {
          bgColor: 'var(--off-white)',
          fullWidth: true,
          fullBleed: true,
          heightDesktop: 'lg:h-[18.75rem]',
          heightTablet: 'md:h-[18.75rem]',
          heightMobile: 'h-[12.5rem]',
        },
      },
    ],
  };
}
