import {
  FLEX_POSITIONS,
  OBJECT_POSITIONS,
  TEXT_COLORS,
} from '@settings/common';

export function Schema() {
  return {
    category: 'Media',
    label: 'Image Tiles',
    key: 'image-tiles',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/image-tiles-preview.jpg?v=1675730325',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Min of 2 tiles, max of 3 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 3,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image (tablet/desktop)',
            name: 'imageDesktop',
            component: 'image',
          },
          {
            label: 'Image Aspect Ratio (tablet/desktop)',
            name: 'aspectDesktop',
            component: 'select',
            options: [
              { label: '3:2', value: 'md:aspect-[3/2]' },
              { label: '4:3', value: 'md:aspect-[4/3]' },
              { label: '5:4', value: 'md:aspect-[5/4]' },
              { label: '1:1', value: 'md:aspect-[1/1]' },
              { label: '4:5', value: 'md:aspect-[4/5]' },
              { label: '3:4', value: 'md:aspect-[3/4]' },
              { label: '2:3', value: 'md:aspect-[2/3]' },
            ],
          },
          {
            label: 'Image Position (tablet/desktop)',
            name: 'positionDesktop',
            component: 'select',
            options: OBJECT_POSITIONS.desktop,
          },
          {
            label: 'Image (mobile)',
            name: 'imageMobile',
            component: 'image',
          },
          {
            label: 'Image Aspect Ratio (mobile)',
            name: 'aspectMobile',
            component: 'select',
            options: [
              { label: '3:2', value: 'aspect-[3/2]' },
              { label: '4:3', value: 'aspect-[4/3]' },
              { label: '5:4', value: 'aspect-[5/4]' },
              { label: '1:1', value: 'aspect-[1/1]' },
              { label: '4:5', value: 'aspect-[4/5]' },
              { label: '3:4', value: 'aspect-[3/4]' },
              { label: '2:3', value: 'aspect-[2/3]' },
            ],
          },
          {
            label: 'Image Position (mobile)',
            name: 'positionMobile',
            component: 'select',
            options: OBJECT_POSITIONS.mobile,
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'Buttons',
            name: 'buttons',
            component: 'group-list',
            description:
              'Max of 2 buttons. Second button will be hidden if image is set to be clickable',
            itemProps: {
              label: '{{item.link.text}}',
            },
            defaultItem: {
              link: {
                text: 'Shop Now',
                url: '',
              },
            },
            validate: {
              maxItems: 2,
            },
            fields: [
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
            ],
          },
        ],
        defaultItem: {
          alt: 'Man in white and light tan outfit',
          imageDesktop: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
          },
          positionDesktop: 'object-center',
          aspectDesktop: 'md:aspect-[1/1]',
          imageMobile: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
          },
          positionMobile: 'object-center',
          aspectMobile: 'aspect-[3/4]',
          heading: 'Headline',
          buttons: [
            {
              link: {
                text: 'Shop Now',
                url: '',
              },
            },
          ],
        },
        defaultValue: [
          {
            alt: 'Man in white and light tan outfit',
            imageDesktop: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
            },
            positionDesktop: 'object-center',
            aspectDesktop: 'md:aspect-[1/1]',
            imageMobile: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
            },
            positionMobile: 'object-center',
            aspectMobile: 'aspect-[3/4]',
            heading: 'Headline',
            buttons: [
              {
                link: {
                  text: 'Shop Now',
                  url: '',
                },
              },
            ],
          },
          {
            alt: 'Man in brown coat sitting down',
            imageDesktop: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/austin-wade-d2s8NQ6WD24-unsplash.jpg?v=1672348122',
            },
            positionDesktop: 'object-center',
            aspectDesktop: 'md:aspect-[1/1]',
            imageMobile: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/austin-wade-d2s8NQ6WD24-unsplash.jpg?v=1672348122',
            },
            positionMobile: 'object-center',
            aspectMobile: 'aspect-[3/4]',
            heading: 'Headline',
            buttons: [
              {
                link: {
                  text: 'Shop Now',
                  url: '',
                },
              },
            ],
          },
          {
            alt: 'Man in gray sweater and tan coat',
            imageDesktop: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-poses-in-light-colored-overcoat.jpg?v=1672348143',
            },
            positionDesktop: 'object-center',
            aspectDesktop: 'md:aspect-[1/1]',
            imageMobile: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-poses-in-light-colored-overcoat.jpg?v=1672348143',
            },
            positionMobile: 'object-center',
            aspectMobile: 'aspect-[3/4]',
            heading: 'Headline',
            buttons: [
              {
                link: {
                  text: 'Shop Now',
                  url: '',
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        description:
          'Dark overlay, content position, button styles, clickable image, hide buttons',
        fields: [
          {
            label: 'Dark Overlay',
            name: 'darkOverlay',
            component: 'toggle',
            description: 'Adds 20% opacity black overlay over media',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Content Position (tablet/desktop)',
            name: 'positionDesktop',
            component: 'select',
            options: FLEX_POSITIONS.desktop,
          },
          {
            label: 'Content Alignment (tablet/desktop)',
            name: 'alignmentDesktop',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'md:text-left md:items-start' },
              { label: 'Center', value: 'md:text-center md:items-center' },
              { label: 'Right', value: 'md:text-right md:items-end' },
            ],
          },
          {
            label: 'Max Content Width (tablet/desktop)',
            name: 'maxWidthDesktop',
            component: 'select',
            options: [
              { label: 'Narrow', value: 'md:max-w-[15rem]' },
              { label: 'Medium', value: 'md:max-w-[25rem]' },
              { label: 'Wide', value: 'md:max-w-[35rem]' },
              { label: 'Full', value: 'md:max-w-full' },
            ],
          },
          {
            label: 'Text Color (desktop)',
            name: 'colorDesktop',
            component: 'select',
            options: TEXT_COLORS.desktop,
          },
          {
            label: 'Content Position (mobile)',
            name: 'positionMobile',
            component: 'select',
            options: FLEX_POSITIONS.mobile,
          },
          {
            label: 'Content Alignment (mobile)',
            name: 'alignmentMobile',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'text-left items-start' },
              { label: 'Center', value: 'text-center items-center' },
              { label: 'Right', value: 'text-right items-end' },
            ],
          },
          {
            label: 'Max Content Width (mobile)',
            name: 'maxWidthMobile',
            component: 'select',
            options: [
              { label: 'Narrow', value: 'max-w-[10rem]' },
              { label: 'Medium', value: 'max-w-[12rem]' },
              { label: 'Wide', value: 'max-w-[15rem]' },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
          {
            label: 'Text Color (mobile)',
            name: 'colorMobile',
            component: 'select',
            options: TEXT_COLORS.mobile,
          },
          {
            label: 'Primary Button Style',
            name: 'primaryButtonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
              { label: 'Text Link', value: 'btn-text-link' },
              { label: 'Text Link (small)', value: 'text-link-sm' },
            ],
          },
          {
            label: 'Secondary Button Style',
            name: 'secondaryButtonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
              { label: 'Text Link', value: 'btn-text-link' },
              { label: 'Text Link (small)', value: 'text-link-sm' },
            ],
          },
          {
            label: 'Clickable Image',
            name: 'clickableImage',
            component: 'toggle',
            description: `Makes entire image clickable using primary button's link; hides any secondary button`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Hide Buttons',
            name: 'hideButtons',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          darkOverlay: true,
          positionDesktop: 'md:justify-start md:items-start',
          alignmentDesktop: 'md:text-left md:items-start',
          maxWidthDesktop: 'max-w-[25rem]',
          colorDesktop: 'md:text-[var(--text)]',
          positionMobile: 'justify-center items-start',
          alignmentMobile: 'text-center items-center',
          maxWidthMobile: 'max-w-[15rem]',
          colorMobile: 'text-[var(--text)]',
          primaryButtonStyle: 'btn-inverse-light',
          secondaryButtonStyle: 'btn-inverse-light',
          clickableImage: true,
          hideButtons: false,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Image aspect ratio, full width',
        fields: [
          {
            label: 'Use Swiper (mobile only)',
            name: 'swiperOnMobile',
            component: 'toggle',
            description:
              'If swiper is set to "Off", tiles will stack vertically in mobile viewport',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          swiperOnMobile: true,
          fullWidth: false,
          fullBleed: false,
        },
      },
    ],
  };
}
