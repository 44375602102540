import { useVariantPrices } from '@hooks';
import { useMemo } from 'react';

export function ProductItemPrice({ selectedVariant, hasOneVariant }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  const sellingPlans = selectedVariant?.sellingPlanAllocations?.map(
    (group) => group.sellingPlan
  );

  const hasSellingPlans = sellingPlans?.length > 0;

  const savingsPercentage =
    sellingPlans?.[0]?.priceAdjustments?.[0].adjustmentValue
      ?.adjustmentPercentage;

  const subscriptionPrice = selectedVariant?.price
    ? ((selectedVariant.price * (100 - savingsPercentage)) / 100).toFixed(2)
    : 0;

  const _price = useMemo(() => {
    if (hasSellingPlans) {
      return `$${subscriptionPrice}+`;
    }
    if (!hasOneVariant && !hasSellingPlans) {
      return `${price}+`;
    }
    return price;
  }, [hasOneVariant, hasSellingPlans]);

  return (
    <div
      data-comp={ProductItemPrice.displayName}
      className="mt-1 flex flex-1 flex-wrap gap-x-1"
    >
      {compareAtPrice && (
        <p className="text-body text-mediumGray line-through">
          {compareAtPrice}
        </p>
      )}
      <p
        className={`${
          compareAtPrice ? 'text-[var(--sale)]' : 'text-text'
        } text-body min-h-[1.25rem]`}
      >
        {_price}
      </p>
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';
