import { useMemo } from 'react';

import { Link, Markdown } from '../../snippets';

export function HalfHeroContent({ aboveTheFold, content, section }) {
  const {
    alignmentDesktop,
    alignmentMobile,
    buttons,
    color,
    heading,
    maxWidthDesktop,
    smallBody,
    subtext,
    superheading,
  } = { ...content };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;

  const headingWithBreaks = useMemo(() => {
    const splitHeading = heading?.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);

  return (
    <div
      data-comp={HalfHeroContent.displayName}
      className={`${section?.fullBleed ? 'px-contained' : ''} ${
        section?.verticalPadding
          ? 'pb-0 pt-8 md:py-10 xl:py-12'
          : 'py-contained'
      } w-full`}
    >
      <div
        className={`mx-auto flex flex-col gap-4 ${alignmentClasses} ${maxWidthDesktop}`}
        style={{ color }}
      >
        {superheading && <p className="text-superheading">{superheading}</p>}

        {headingWithBreaks && aboveTheFold && (
          <h1 className="text-title-h2">{headingWithBreaks}</h1>
        )}
        {headingWithBreaks && !aboveTheFold && (
          <h2 className="text-title-h2">{headingWithBreaks}</h2>
        )}

        {subtext && (
          <div className="[&_a]:underline [&_p]:text-base [&_ul]:text-left">
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        {smallBody && (
          <div className="mt-2 [&_*]:text-left [&_a]:underline [&_p]:!mb-0 [&_p]:!text-2xs">
            <Markdown>{smallBody}</Markdown>
          </div>
        )}

        {buttons?.length > 0 && (
          <ul className="mt-4 flex flex-col justify-center gap-4 xs:flex-row">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={link?.text}
                    className={style}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

HalfHeroContent.displayName = 'HalfHeroContent';
