import { useMemo } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';

export function useCartItemImage({ item }) {
  const { variant } = { ...item };
  const { product } = useProductByHandle({ handle: variant?.product?.handle });

  return useMemo(() => {
    const hasMultipleColors = product?.optionsMap?.Color?.length > 1;
    if (!product || !hasMultipleColors) return variant?.image;

    const variantColor = variant.selectedOptions
      .find(({ name }) => name === 'Color')
      ?.value?.toLowerCase();

    return variantColor
      ? product.images.find(({ altText }) => {
          const imageColor = altText?.toLowerCase().trim();
          return imageColor === variantColor;
        }) || variant.image
      : variant.image;
  }, [product?.id, variant?.id]);
}
