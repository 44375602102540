import { useInView } from 'react-intersection-observer';

import { Image, Link } from '@snippets';

import { PromoTileContent } from './PromoTileContent';

export function PromoTile({ tile }) {
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const { background, link, linkStyle, buttonStyle, text } = tile;
  const { alt, bgColor, darkOverlay, image, videoPoster, videoSrc } = {
    ...background,
  };

  return (
    <Link
      data-comp={PromoTile.displayName}
      aria-label={text?.heading || link?.text}
      className="h-full"
      href={linkStyle === 'button' ? null : link?.url}
      newTab={link?.newTab}
      ref={ref}
      type={link?.type}
    >
      <div
        className="relative h-full overflow-hidden"
        style={{
          background: image ? 'var(--off-white)' : bgColor,
          backgroundImage: bgColor,
        }}
      >
        {videoSrc && (
          <video
            autoPlay
            className="absolute inset-0 h-full w-full object-cover"
            controls={false}
            loop
            muted
            playsInline
            poster={videoPoster?.src}
          >
            {inView && <source src={videoSrc} type="video/mp4" />}
          </video>
        )}

        {image?.src && !videoSrc && (
          <Image
            alt={alt}
            fill
            sizes="(min-width: 768px) 33vw, 50vw"
            src={image.src}
          />
        )}

        {(videoSrc || image?.src) && darkOverlay && (
          <div className="pointer-events-none absolute inset-0 h-full w-full bg-[rgba(0,0,0,0.2)]" />
        )}

        {(text?.heading || text?.subtext || text?.image?.src) && (
          <PromoTileContent
            buttonStyle={buttonStyle}
            link={link}
            linkStyle={linkStyle}
            text={text}
          />
        )}
      </div>
    </Link>
  );
}

PromoTile.displayName = 'PromoTile';
