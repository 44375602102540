import React from 'react';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider';
import { Link } from '@snippets';
import { Schema } from './RevealSlider.schema';

export function RevealSlider({ cms }) {
  const { content, image, section } = cms;

  const { buttons, heading, text, themeColor } = { ...content };
  const {
    aspectDesktop,
    aspectMobile,
    aspectTablet,
    image1,
    image2,
    maxWidth,
  } = {
    ...image,
  };

  return (
    <div
      className={`${section?.fullBleed ? '' : 'px-10'} ${
        section?.verticalPadding ? 'py-contained' : ''
      }`}
      style={{
        background: section?.bgColor,
      }}
    >
      <div className={`mx-auto text-center ${section?.maxWidth}`}>
        <div className="mb-6 md:mb-8">
          {heading && (
            <h2 className="text-title-h2 mb-3 md:text-[46px] md:leading-[50px]">
              {heading}
            </h2>
          )}

          {text && <p>{text}</p>}

          {buttons?.length > 0 && (
            <ul className="mt-4 flex flex-col justify-center gap-4 xs:flex-row">
              {buttons.slice(0, 2).map(({ link, style }, index) => {
                return (
                  <li key={index}>
                    <Link
                      aria-label={link?.text}
                      className={style}
                      href={link?.url}
                      newTab={link?.newTab}
                      type={link?.type}
                    >
                      {link?.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <style>
          {`
            [data-comp-id="${
              cms?.clientId || cms?.cmsId
            }"] .__rcs-handle-button {
              border-color: transparent;
              background-color: rgba(239, 204, 184, 0.9) !important;
              width: 36px !important;
              height: 36px !important;
              gap: 6px !important;

            }
            [data-comp-id="${
              cms?.clientId || cms?.cmsId
            }"] .__rcs-handle-arrow {
              color: ${themeColor};
              border-top: 6px solid transparent !important;
              border-right: 9px solid !important;
              border-bottom: 6px solid transparent !important;
            }
            [data-comp-id="${cms?.clientId || cms?.cmsId}"] .__rcs-handle-line {
              background-color: ${themeColor} !important;
              width: 5px !important;
            }
          `}
        </style>
        <div
          className={`mx-auto ${aspectMobile} ${aspectTablet} ${aspectDesktop}`}
          style={{
            maxWidth: `${maxWidth || '620'}px`,
          }}
        >
          <div className="flex h-full justify-center">
            <ReactCompareSlider
              style={{
                border: `5px solid ${themeColor}`,
                borderRadius: '30px',
                width: '100%',
              }}
              itemOne={
                <ReactCompareSliderImage
                  src={
                    image1?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/before.webp'
                  }
                  srcSet={
                    image1?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/before.webp'
                  }
                  alt={image1?.alt || 'Image one'}
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={
                    image2?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/after.webp'
                  }
                  srcSet={
                    image2?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/after.webp'
                  }
                  alt={image2?.alt || 'Image two'}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

RevealSlider.displayName = 'RevealSlider';
RevealSlider.Schema = Schema;
