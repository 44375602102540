import { Accordions } from './Accordions';
import { BlogCategories } from './BlogCategories';
import { BlogGrid } from './BlogGrid';
import { Collection } from './Collection';
import { CollectionHero } from './CollectionHero';
import { FormBuilder } from './FormBuilder';
import { HalfHero } from './HalfHero';
import { HalfHeroReveal } from './HalfHeroReveal';
import { Hero } from './Hero';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { PdpModal } from './PdpModal';
import { Image } from './Image';
import { ImageTiles } from './ImageTiles';
import { MadeWith } from './MadeWith';
import { Markdown } from './Markdown';
import { PressSlider } from './PressSlider';
import { ProductDetailAccordions } from './ProductDetailAccordions';
import { ProductReviews } from './ProductReviews';
import { ProductsSlider } from './ProductsSlider';
import { RevealSlider } from './RevealSlider';
import { ReviewsIO } from './ReviewsIO/ReviewsIO';
import { ScrollingBanner } from './ScrollingBanner';
import { SearchResults } from './SearchResults';
import { SocialImagesGrid } from './SocialImagesGrid';
import { StoreReviews } from './StoreReviews';
import { TabbedThreeTiles } from './TabbedThreeTiles';
import { TestimonialSlider } from './TestimonialSlider';
import { TextBlock } from './TextBlock';
import { ThreeTiles } from './ThreeTiles';
import { TwoTiles } from './TwoTiles';
import { Video } from './Video';

export default [
  Accordions,
  BlogCategories,
  BlogGrid,
  Collection,
  CollectionHero,
  FormBuilder,
  HalfHero,
  HalfHeroReveal,
  Hero,
  IconRow,
  PdpModal,
  Image,
  ImageTiles,
  MadeWith,
  Markdown,
  PressSlider,
  ProductDetailAccordions,
  ProductReviews,
  ProductsSlider,
  RevealSlider,
  ReviewsIO,
  ScrollingBanner,
  SearchResults,
  SocialImagesGrid,
  StoreReviews,
  TabbedThreeTiles,
  TestimonialSlider,
  TextBlock,
  ThreeTiles,
  TwoTiles,
  Video,
  Html,
];
