import { COLORS } from '../../settings/common';

export const Schema = () => {
  return {
    label: 'Reveal Slider',
    key: 'reveal-slider',
    category: 'Text',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/reveal-slider-preview.png?v=1701209709',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, superheading, subtext, buttons, theme color',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Text',
            name: 'text',
            component: 'text',
          },
          {
            label: 'Theme Color',
            name: 'themeColor',
            component: 'color',
            defaultValue: '#672C45',
          },
          {
            label: 'Buttons',
            name: 'buttons',
            component: 'group-list',
            description: 'Max of two buttons',
            itemProps: {
              label: '{{item.link.text}}',
            },
            validate: {
              maxItems: 2,
            },
            fields: [
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
              {
                label: 'Button Style',
                name: 'style',
                component: 'select',
                options: [
                  { label: 'Primary', value: 'btn-primary' },
                  { label: 'Secondary', value: 'btn-secondary' },
                  { label: 'Inverse Light', value: 'btn-inverse-light' },
                  { label: 'Inverse Dark', value: 'btn-inverse-dark' },
                  { label: 'Text Link', value: 'btn-text-link' },
                ],
              },
            ],
            defaultItem: {
              link: { text: 'Shop Now', url: '' },
              style: 'btn-primary',
            },
          },
        ],
      },
      {
        label: 'Image Settings',
        name: 'image',
        description: 'Image, alt, aspect ratio, max width',
        component: 'group',
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image 1',
            name: 'image1',
            component: 'image',
          },
          {
            label: 'Image Alt 2',
            name: 'alt2',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image 2',
            name: 'image2',
            component: 'image',
          },
          {
            label: 'Max Width',
            name: 'maxWidth',
            component: 'number',
            defaultValue: 620,
          },
          {
            label: 'Media Aspect Ratio (tablet)',
            name: 'aspectTablet',
            component: 'select',
            options: [
              { label: '3:1.5', value: 'md:aspect-[3/1.5]' },
              { label: '3:2', value: 'md:aspect-[3/2]' },
              { label: '4:3', value: 'md:aspect-[4/3]' },
              { label: '5:4', value: 'md:aspect-[5/4]' },
              { label: '8:7', value: 'md:aspect-[8/7]]' },
              { label: '1:1', value: 'md:aspect-[1/1]' },
              { label: '7:8', value: 'md:aspect-[7/8]' },
              { label: '4:5', value: 'md:aspect-[4/5]' },
              { label: '3:4', value: 'md:aspect-[3/4]' },
              { label: '2:3', value: 'md:aspect-[2/3]' },
            ],
          },
          {
            label: 'Media Aspect Ratio (desktop)',
            name: 'aspectDesktop',
            component: 'select',
            options: [
              { label: '3:1.5', value: 'lg:aspect-[3/1.5]' },
              { label: '3:2', value: 'lg:aspect-[3/2]' },
              { label: '4:3', value: 'lg:aspect-[4/3]' },
              { label: '5:4', value: 'lg:aspect-[5/4]' },
              { label: '8:7', value: 'lg:aspect-[8/7]]' },
              { label: '1:1', value: 'lg:aspect-[1/1]' },
              { label: '7:8', value: 'lg:aspect-[7/8]' },
              { label: '4:5', value: 'lg:aspect-[4/5]' },
              { label: '3:4', value: 'lg:aspect-[3/4]' },
              { label: '2:3', value: 'lg:aspect-[2/3]' },
            ],
          },
          {
            label: 'Media Aspect Ratio (mobile)',
            name: 'aspectMobile',
            component: 'select',
            options: [
              { label: '3:1.5', value: 'max-md:aspect-[3/1.5]' },
              { label: '3:2', value: 'max-md:aspect-[3/2]' },
              { label: '4:3', value: 'max-md:aspect-[4/3]' },
              { label: '5:4', value: 'max-md:aspect-[5/4]' },
              { label: '8:7', value: 'max-md:aspect-[8/7]]' },
              { label: '1:1', value: 'max-md:aspect-[1/1]' },
              { label: '7:8', value: 'max-md:aspect-[7/8]' },
              { label: '4:5', value: 'max-md:aspect-[4/5]' },
              { label: '3:4', value: 'max-md:aspect-[3/4]' },
              { label: '2:3', value: 'max-md:aspect-[2/3]' },
            ],
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'background color, full bleed, max content width, vertical padding',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: true,
          fullBleed: true,
          verticalPadding: false,
        },
      },
    ],
  };
};
