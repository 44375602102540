import { useMemo } from 'react';

import { Image as ImageSnippet, Link, Markdown } from '../../snippets';
import { Schema } from './Image.schema';

export function Image({ cms }) {
  const { content, image, section } = cms;
  const {
    alt,
    aspectDesktop,
    aspectMobile,
    imageDesktop,
    imageMobile,
    positionDesktop,
    positionMobile,
  } = {
    ...image,
  };
  const { caption, link } = { ...content };
  const { maxWidth, enablePadding } = { ...section };

  const sizes = useMemo(() => {
    return /[0-9]+(?:px)|[0-9]+(?:rem)/.exec(maxWidth)?.[0] || '100vw';
  }, [maxWidth]);

  return (
    <div
      className={`py-4 md:py-6 lg:py-8 ${enablePadding ? 'px-contained' : ''}`}
    >
      <Link
        aria-label={link?.text}
        className={`mx-auto ${maxWidth}`}
        href={link?.url}
        newTab={link?.newTab}
        type={link?.type}
      >
        <div className={`relative bg-offWhite md:hidden ${aspectMobile}`}>
          {imageMobile?.src && (
            <ImageSnippet
              alt={alt}
              className={`${positionMobile}`}
              fill
              sizes={sizes}
              src={imageMobile.src}
            />
          )}
        </div>

        <div
          className={`relative hidden bg-offWhite md:block ${aspectDesktop}`}
        >
          {imageDesktop?.src && (
            <ImageSnippet
              alt={alt}
              className={`${positionDesktop}`}
              fill
              sizes={sizes}
              src={imageDesktop.src}
            />
          )}
        </div>
      </Link>

      {caption && (
        <div className={`mt-3 ${enablePadding ? '' : 'px-contained'}`}>
          <div
            className={`mx-auto [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base ${maxWidth}`}
          >
            <Markdown>{caption}</Markdown>
          </div>
        </div>
      )}
    </div>
  );
}

Image.displayName = 'Image';
Image.Schema = Schema;
