import { Fragment } from 'react';
import { Link } from '@snippets';

import { Schema } from './ScrollingBanner.schema';

export function ScrollingBanner({ cms, inView }) {
  const {
    messages,
    textMultiplier,
    fontFamily,
    fontSize,
    color,
    bgColor,
    scrollingSpeed,
    spacing,
  } = cms;

  const { enableSpacingBetweenMessages, width: widthBetweenMessages } = {
    ...spacing,
  };

  return (
    <div
      data-comp={ScrollingBanner.displayName}
      className="relative flex w-full items-center overflow-x-hidden"
      style={{ background: bgColor, backgroundImage: bgColor }}
    >
      <div className="flex">
        {[...Array(textMultiplier || 2).keys()].map((_, index) => {
          return (
            <div
              key={index}
              className="relative flex shrink-0 overflow-x-hidden"
              style={{
                animation: `scroll ${scrollingSpeed || '10'}s infinite linear`,
              }}
            >
              {messages?.map(({ text, link, enableLink }, _index) => {
                return (
                  <Fragment key={_index}>
                    <Link
                      as={link?.url && enableLink ? Link : 'div'}
                      href={enableLink ? link?.url : null}
                    >
                      <p
                        className={`${color} ${fontSize} ${fontFamily} mr-2 leading-none`}
                      >
                        {text}
                      </p>
                    </Link>
                    {enableSpacingBetweenMessages && (
                      <p
                        style={{
                          width: widthBetweenMessages,
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

ScrollingBanner.displayName = 'ScrollingBanner';
ScrollingBanner.Schema = Schema;
