import { useState } from 'react';

import { Markdown, Svg } from '@snippets';

export function ProductDetailAccordion({
  accordion,
  headerBgColor,
  headerTextColor,
}) {
  const { body, smallBody, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div data-comp={ProductDetailAccordion.displayName}>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="relative flex h-16 w-full flex-col items-center justify-center gap-x-4"
        onClick={() => setIsOpen(!isOpen)}
        style={{ background: headerBgColor, color: headerTextColor }}
        type="button"
      >
        <div className="flex w-full items-center justify-between px-6 xl:px-8">
          <h3 className="text-title-h5">{header}</h3>

          {isOpen ? (
            <Svg
              className="w-4 text-current"
              src="/svgs/minus.svg#minus"
              title="Minus"
              viewBox="0 0 24 24"
            />
          ) : (
            <Svg
              className="w-4 text-current"
              src="/svgs/plus.svg#plus"
              title="Plus"
              viewBox="0 0 24 24"
            />
          )}
        </div>

        <img
          alt=""
          src="/images/wave.png"
          className="absolute bottom-[0] z-10 w-full translate-y-[50%]"
        />
      </button>

      <div
        className={`text-body [&_h1]:text-body [&_h2]:text-body [&_h3]:text-body [&_h4]:text-body [&_h5]:text-body [&_h6]:text-body [&_ol]:text-body [&_p]:text-body [&_ul]:text-body px-6  pt-5  xl:px-9 [&_h1]:mb-3 [&_h2]:mb-3 [&_h3]:mb-3 [&_h4]:mb-3 [&_h5]:mb-3 [&_h6]:mb-3 [&_li]:!mb-0 [&_ol]:!pl-4 [&_p]:mb-2 [&_ul]:!pl-4 ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <Markdown>{body}</Markdown>
        <div className="mt-2 [&_p]:!mb-0 [&_p]:!text-2xs">
          <Markdown>{smallBody}</Markdown>
        </div>
      </div>
    </div>
  );
}

ProductDetailAccordion.displayName = 'ProductDetailAccordion';
