export const DEFAULT_SRC_SET_SIZES = [480, 960, 1280, 1920, 2560];
const CDN_HOSTNAMES = [
  'cdn.shopify.com',
  process.env.SITE_URL?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split(
    '/'
  )[0],
];
const DEFAULT_QUALITY = 75;

// Adds image size parameters to an image URL hosted by Shopify's CDN
export function addImageSizeParametersToUrl({
  src,
  width,
  height,
  crop,
  scale,
  quality = DEFAULT_QUALITY,
}) {
  try {
    const newUrl = new URL(src);

    const multipliedScale = width && height ? scale || 2 : scale || 1;

    if (width) {
      let finalWidth;

      if (typeof width === 'string') {
        finalWidth = (DEFAULT_SRC_SET_SIZES[0] * multipliedScale).toString();
      } else {
        finalWidth = (Number(width) * multipliedScale).toString();
      }

      newUrl.searchParams.append('width', finalWidth);
    }

    // height and crop are only applied if both are provided
    if (height && typeof height === 'number' && crop) {
      newUrl.searchParams.append(
        'height',
        (height * multipliedScale).toString()
      );
      newUrl.searchParams.append('crop', crop);
    }

    newUrl.searchParams.append('quality', quality);

    return newUrl.toString();
  } catch (error) {
    return src;
  }
}

export function shopifyImageLoader(params) {
  try {
    const newSrc = new URL(params.src);
    const isShopifyServedImage = CDN_HOSTNAMES.some((allowedHostname) =>
      newSrc.hostname.endsWith(allowedHostname)
    );

    if (
      !isShopifyServedImage ||
      (!params.width &&
        !params.height &&
        !params.crop &&
        !params.scale &&
        !params.quality)
    ) {
      return params.src;
    }

    return addImageSizeParametersToUrl(params);
  } catch (error) {
    return params.src;
  }
}
