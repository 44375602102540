import {
  COLORS,
  FLEX_POSITIONS,
  OBJECT_POSITIONS,
} from '../../settings/common';

const defaultSlide = {
  image: {
    alt: 'Man with backpack crossing the street',
    imageDesktop: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
    },
    positionDesktop: 'md:object-center',
    imageMobile: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
    },
    positionMobile: 'object-center',
  },
  video: {
    autoplay: true,
    sound: false,
  },
};

const media = {
  label: 'Media Settings',
  name: 'media',
  description: 'Image settings, video settings, order, aspect ratio',
  component: 'group',
  fields: [
    {
      name: 'slides',
      label: 'Slides',
      component: 'group-list',
      itemProps: {
        label: '{{item.image.alt}}',
      },
      fields: [
        {
          label: 'Image Settings',
          name: 'image',
          description: 'Image, image position',
          component: 'group',
          fields: [
            {
              label: 'Image Alt',
              name: 'alt',
              component: 'text',
              description: 'Brief description of image',
            },
            {
              label: 'Image (tablet/desktop)',
              name: 'imageDesktop',
              component: 'image',
            },
            {
              label: 'Image Position (tablet/desktop)',
              name: 'positionDesktop',
              component: 'select',
              options: OBJECT_POSITIONS.desktop,
            },
            {
              label: 'Image (mobile)',
              name: 'imageMobile',
              component: 'image',
            },
            {
              label: 'Image Position (mobile)',
              name: 'positionMobile',
              component: 'select',
              options: OBJECT_POSITIONS.mobile,
            },
          ],
        },
        {
          label: 'Video Settings',
          name: 'video',
          description: 'Video link, poster image, autoplay, sound',
          component: 'group',
          fields: [
            {
              label: 'Video URL (tablet/desktop)',
              name: 'srcDesktop',
              component: 'text',
              description: 'Overrides tablet/desktop image option',
            },
            {
              label: 'Poster Image (tablet/desktop)',
              name: 'posterDesktop',
              component: 'image',
            },
            {
              label: 'Video URL (mobile)',
              name: 'srcMobile',
              component: 'text',
              description: 'Overrides mobile image option',
            },
            {
              label: 'Poster Image (mobile)',
              name: 'posterMobile',
              component: 'image',
            },
            {
              label: 'Autoplay When In View',
              name: 'autoplay',
              component: 'toggle',
              description:
                'Disabling controls video through play button instead. Also applies muted and loop attributes',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Sound',
              name: 'sound',
              component: 'toggle',
              description:
                'Only applicable if autoplay is off. Also applies controls attribute',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
          ],
        },
      ],
      defaultValue: [defaultSlide],
      defaultItem: defaultSlide,
    },

    {
      label: 'Slider Settings',
      name: 'slider',
      description: 'Autoplay, delay, transition effect, bullet color',
      component: 'group',
      fields: [
        {
          label: 'Enable Autoplay',
          name: 'autoplay',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Pagination Bullets',
          name: 'pagination',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Delay Between Transitions (ms)',
          name: 'delay',
          component: 'number',
        },
        {
          label: 'Effect Between Transitions',
          name: 'effect',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            { label: 'Slide', value: 'slide' },
            { label: 'Fade', value: 'fade' },
          ],
        },
        {
          label: 'Active Bullet Color',
          name: 'activeBulletColor',
          component: 'select',
          options: COLORS,
        },
      ],
      defaultValue: {
        autoplay: true,
        pagination: true,
        delay: 8000,
        effect: 'fade',
        activeBulletColor: 'var(--white)',
      },
    },

    {
      label: 'Media Order (tablet/desktop)',
      name: 'mediaOrderDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: '1' },
        { label: 'Right', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (tablet)',
      name: 'aspectTablet',
      component: 'select',
      options: [
        { label: '3:2', value: 'md:before:pb-[67%]' },
        { label: '4:3', value: 'md:before:pb-[75%]' },
        { label: '5:4', value: 'md:before:pb-[80%]' },
        { label: '8:7', value: 'md:before:pb-[87.5%]' },
        { label: '1:1', value: 'md:before:pb-[100%]' },
        { label: '7:8', value: 'md:before:pb-[114%]' },
        { label: '4:5', value: 'md:before:pb-[125%]' },
        { label: '3:4', value: 'md:before:pb-[133%]' },
        { label: '2:3', value: 'md:before:pb-[150%]' },
      ],
    },
    {
      label: 'Media Aspect Ratio (desktop)',
      name: 'aspectDesktop',
      component: 'select',
      options: [
        { label: '3:2', value: 'lg:before:pb-[67%]' },
        { label: '4:3', value: 'lg:before:pb-[75%]' },
        { label: '5:4', value: 'lg:before:pb-[80%]' },
        { label: '8:7', value: 'lg:before:pb-[87.5%]' },
        { label: '1:1', value: 'lg:before:pb-[100%]' },
        { label: '7:8', value: 'lg:before:pb-[114%]' },
        { label: '4:5', value: 'lg:before:pb-[125%]' },
        { label: '3:4', value: 'lg:before:pb-[133%]' },
        { label: '2:3', value: 'lg:before:pb-[150%]' },
      ],
    },
    {
      label: 'Fill Empty Space (tablet/desktop)',
      name: 'fill',
      component: 'toggle',
      description:
        'Fill any vertical empty space with media. Applicable only to tablet and desktop',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Media Order (mobile)',
      name: 'mediaOrderMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Top', value: '1' },
        { label: 'Bottom', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (mobile)',
      name: 'aspectMobile',
      component: 'select',
      options: [
        { label: '3:2', value: 'max-md:before:pb-[67%]' },
        { label: '4:3', value: 'max-md:before:pb-[75%]' },
        { label: '5:4', value: 'max-md:before:pb-[80%]' },
        { label: '8:7', value: 'max-md:before:pb-[87.5%]' },
        { label: '1:1', value: 'max-md:before:pb-[100%]' },
        { label: '7:8', value: 'max-md:before:pb-[114%]' },
        { label: '4:5', value: 'max-md:before:pb-[125%]' },
        { label: '3:4', value: 'max-md:before:pb-[133%]' },
        { label: '2:3', value: 'max-md:before:pb-[150%]' },
      ],
    },
  ],
  defaultValue: {
    mediaOrderDesktop: '1',
    aspectTablet: 'md:before:pb-[100%]',
    aspectDesktop: 'lg:before:pb-[100%]',
    fill: true,
    mediaOrderMobile: '1',
    aspectMobile: 'max-md:before:pb-[100%]',
  },
};

const spinningImage = {
  label: 'Spinning Image Settings',
  name: 'spinningImage',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      label: 'Image - Background',
      name: 'imageBackground',
      component: 'group',
      fields: [
        {
          label: 'Enable Endless Rotation Animation',
          name: 'enabledRotationAnimation',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Animation Speed',
          name: 'animationSpeed',
          component: 'number',
          defaultValue: 10,
        },
        {
          label: 'Alt',
          name: 'alt',
          component: 'text',
        },
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          label: 'Width (tablet/desktop)',
          name: 'widthDesktop',
          component: 'number',
        },
        {
          label: 'Height (tablet/desktop)',
          name: 'heightDesktop',
          component: 'number',
        },
        {
          label: 'Width (mobile)',
          name: 'widthMobile',
          component: 'number',
        },
        {
          label: 'Height (mobile)',
          name: 'heightMobile',
          component: 'number',
        },
      ],
    },
    {
      label: 'Image - Stacked',
      name: 'imageStacked',
      component: 'group',
      fields: [
        {
          label: 'Enable Endless Rotation Animation',
          name: 'enabledRotationAnimation',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Animation Speed',
          name: 'animationSpeed',
          component: 'number',
          defaultValue: 10,
        },
        {
          label: 'Alt',
          name: 'alt',
          component: 'text',
        },
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          label: 'Width (tablet/desktop)',
          name: 'widthDesktop',
          component: 'number',
        },
        {
          label: 'Height (tablet/desktop)',
          name: 'heightDesktop',
          component: 'number',
        },
        {
          label: 'Width (mobile)',
          name: 'widthMobile',
          component: 'number',
        },
        {
          label: 'Height (mobile)',
          name: 'heightMobile',
          component: 'number',
        },
      ],
    },
    {
      label: 'Image Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: FLEX_POSITIONS.desktop,
    },
    {
      label: 'Image Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: FLEX_POSITIONS.mobile,
    },
  ],
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description:
    'Heading, superheading, subtext, buttons, text color, alignment, content width',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'textarea',
    },
    {
      label: 'Superheading',
      name: 'superheading',
      component: 'text',
    },
    {
      label: 'Subtext',
      name: 'subtext',
      component: 'markdown',
    },
    {
      label: 'Small Body',
      name: 'smallBody',
      component: 'markdown',
    },
    {
      label: 'Buttons',
      name: 'buttons',
      component: 'group-list',
      description: 'Max of two buttons',
      itemProps: {
        label: '{{item.link.text}}',
      },
      validate: {
        maxItems: 2,
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Button Style',
          name: 'style',
          component: 'select',
          options: [
            { label: 'Primary', value: 'btn-primary' },
            { label: 'Secondary', value: 'btn-secondary' },
            { label: 'Inverse Light', value: 'btn-inverse-light' },
            { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            { label: 'Text Link', value: 'btn-text-link' },
          ],
        },
      ],
      defaultItem: {
        link: { text: 'Shop Now', url: '' },
        style: 'btn-primary',
      },
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: COLORS,
    },
    {
      label: 'Content Alignment (tablet/desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
        { label: 'Right', value: 'md:text-right md:items-end' },
      ],
    },
    {
      label: 'Max Content Width (tablet/desktop)',
      name: 'maxWidthDesktop',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'md:max-w-[22rem] lg:max-w-[28rem]' },
        { label: 'Medium', value: 'md:max-w-[30rem] lg:max-w-[38rem]' },
        { label: 'Wide', value: 'md:max-w-[38rem] lg:max-w-[48rem]' },
        { label: 'Full', value: 'md:max-w-full' },
      ],
    },
    {
      label: 'Content Alignment (mobile)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
    },
  ],
  defaultValue: {
    heading: 'Half Hero Heading',
    superheading: '',
    subtext:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    buttons: [
      {
        link: { text: 'Shop Now', url: '' },
        style: 'btn-primary',
      },
    ],
    color: 'var(--text)',
    alignmentDesktop: 'md:text-left md:items-start',
    maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
    alignmentMobile: 'text-left items-start',
  },
};

export const Schema = () => {
  return {
    category: 'Hero',
    label: 'Half Hero',
    key: 'half-hero',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/half-hero-preview_396b489b-da92-490a-b59d-87b2c9467c67.jpg?v=1675795226',
    fields: [
      media,
      spinningImage,
      content,
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Background color, above the fold, full width, full bleed, vertical padding',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Above The Fold',
            name: 'aboveTheFold',
            component: 'toggle',
            description: `Sets the heading as H1 and image load as priority`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          bgColor: 'var(--background)',
          aboveTheFold: false,
          fullWidth: true,
          fullBleed: true,
          verticalPadding: false,
        },
      },
    ],
  };
};
