import Script from 'next/script';

export function StorefrontScripts() {
  return (
    <>
      <Script
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script={process.env.NEXT_PUBLIC_OT_DOMAIN_SCRIPT}
        strategy="beforeInteractive"
      />
      <Script
        id="ot-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() { };`,
        }}
      />
      {process.env.NEXT_PUBLIC_GTM_CONTAINER_ID && (
        <Script
          id="gtm-script"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');`,
          }}
        />
      )}

      <Script
        type="text/javascript"
        id="klaviyo-script"
        defer
        strategy="beforeInteractive"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY}`}
      />

      <Script
        type="text/javascript"
        id="catch-script"
        src="https://js.getcatch.com/catchjs/v1/catch.js"
        onLoad={() => {
          if (window.catchjs) {
            catchjs.init(process.env.NEXT_PUBLIC_CATCH_API_KEY, {
              // Optional configuration settings
            });
          }
        }}
      />

      <Script
        id="gorgias-chat-widget-install-v3"
        src="https://config.gorgias.chat/bundle-loader/01HDM7WW5TW7DYSF61Q52WPXAX"
      />
    </>
  );
}

StorefrontScripts.displayName = 'StorefrontScripts';
