import { forwardRef, useMemo } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

// nextjs link docs: https://nextjs.org/docs/api-reference/next/link

const getValidatedHref = ({ href, type }) => {
  if (!href) return '';
  if (type === 'isPage') {
    return href;
  }
  if (type === 'isExternal') {
    if (href.startsWith('/')) return href;
    let externalHref;
    try {
      externalHref = new URL(href).href;
    } catch (error) {
      externalHref = `https://${href}`;
    }
    return externalHref;
  }
  if (type === 'isEmail') {
    return href.startsWith('mailto:') ? href : `mailto:${href}`;
  }
  if (type === 'isPhone') {
    return href.startsWith('tel:') ? href : `tel:${href}`;
  }
  return href;
};

export const Link = forwardRef(
  (
    {
      as,
      children,
      className,
      href = '',
      isExternal = false, // cms property
      locale = false,
      newTab = false,
      prefetch = false,
      replace = false,
      scroll = true,
      shallow = false,
      style,
      text = '', // cms property
      type = 'isPage', // cms property
      url = '', // cms property
      ...props
    },
    ref
  ) => {
    const isHrefObject = !!href && typeof href === 'object';
    const initialHref = isHrefObject ? href?.pathname : href || url;

    const finalHref = useMemo(() => {
      return getValidatedHref({
        href: initialHref,
        type: isExternal ? 'isExternal' : type,
      });
    }, [initialHref, isExternal, type]);

    return finalHref ? (
      <NextLink
        as={as}
        href={isHrefObject ? { ...href, pathname: finalHref } : finalHref}
        locale={locale}
        passHref
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        {...(!prefetch ? { prefetch: false } : null)}
      >
        <a
          className={className}
          ref={ref}
          style={style}
          {...(newTab ? { target: '_blank' } : null)}
          {...props}
        >
          {children || text}
        </a>
      </NextLink>
    ) : (
      <div className={className} ref={ref} style={style}>
        {children || text}
      </div>
    );
  }
);

Link.displayName = 'Link';
Link.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isExternal: PropTypes.bool,
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  newTab: PropTypes.bool,
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  style: PropTypes.object,
  text: PropTypes.string,
  type: PropTypes.oneOf(['isPage', 'isExternal', 'isEmail', 'isPhone']),
  url: PropTypes.string,
};
