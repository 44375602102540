import { promoBar } from './promoBar';
import { rotatingBar } from './rotatingBar';
import { menu } from './menu';

export default {
  label: 'Header',
  name: 'header',
  component: 'group',
  description: 'Menu, promobar',
  fields: [
    {
      label: 'Enable Transparent Header on Homepage',
      name: 'enableTransparentBackground',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    promoBar,
    rotatingBar,
    menu,
  ],
};
