import React from 'react';

import { Image, Link } from '@snippets';

export function PromoTileContent({ buttonStyle, link, linkStyle, text }) {
  const {
    heading,
    headingStyle,
    subtext,
    textColor,
    image,
    alt,
    position,
    maxContentWidth,
  } = {
    ...text,
  };

  const imageAspectRatio =
    image?.width && image?.height ? image.width / image.height : 1;

  return (
    <div
      className={`absolute w-full ${maxContentWidth} px-4 text-center ${
        position || 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
      }`}
      style={{ color: textColor }}
    >
      {image?.src && (
        <div
          className="relative"
          style={{
            aspectRatio: imageAspectRatio,
          }}
        >
          <Image
            alt={alt}
            fill
            sizes="(min-width: 768px) 33vw, 50vw"
            src={image.src}
          />
        </div>
      )}
      <h3 className={`${headingStyle}`}>{heading}</h3>
      {subtext && <p className="text-body mt-4">{subtext}</p>}
      {linkStyle === 'button' && link?.text && (
        <Link
          className={`mt-4 max-w-[100%] ${buttonStyle}`}
          aria-label={heading || link?.text}
          href={linkStyle === 'clickableTile' ? null : link?.url}
          newTab={link?.newTab}
          type={link?.type}
        >
          {link?.text}
        </Link>
      )}
    </div>
  );
}

PromoTileContent.displayName = 'PromoTileContent';
