import { forwardRef, useMemo } from 'react';
import Head from 'next/head';

import {
  shopifyImageLoader,
  addImageSizeParametersToUrl,
  DEFAULT_SRC_SET_SIZES,
} from './image-utils';

function internalImageSrcSet({
  src,
  width,
  crop,
  scale,
  widths,
  loader,
  height,
  quality,
}) {
  const hasCustomWidths = widths && Array.isArray(widths);

  let aspectRatio = 1;
  if (width && height) {
    aspectRatio = Number(height) / Number(width);
  }

  let setSizes = hasCustomWidths ? widths : DEFAULT_SRC_SET_SIZES;
  if (
    !hasCustomWidths &&
    width &&
    width < DEFAULT_SRC_SET_SIZES[DEFAULT_SRC_SET_SIZES.length - 1]
  ) {
    setSizes = DEFAULT_SRC_SET_SIZES.filter((size) => size <= width);
  }
  const srcGenerator = loader || addImageSizeParametersToUrl;
  return setSizes
    .map(
      (size) =>
        `${srcGenerator({
          src,
          width: size,
          // height is applied as a ratio of the original width + height aspect ratio * size
          height: height ? Number(size) * aspectRatio : undefined,
          crop,
          scale,
          quality,
        })} ${size ?? ''}w`
    )
    .join(', ');
}

function getInt(value) {
  if (typeof value === 'number' || typeof value === 'undefined') {
    return value;
  }
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return NaN;
}

export const ImageElement = forwardRef(
  (
    {
      alt,
      className = '',
      crop,
      decoding = 'async',
      fill,
      height,
      loader = shopifyImageLoader,
      loading = 'eager',
      priority,
      quality,
      scale,
      sizes,
      src,
      srcSetSizes,
      width,
      ...props
    },
    ref
  ) => {
    const widthInt = useMemo(() => getInt(width), [width]);
    const heightInt = useMemo(() => getInt(height), [height]);
    const qualityInt = useMemo(() => getInt(quality), [quality]);
    const scaleInt = useMemo(() => getInt(scale), [scale]);

    const finalSrc = useMemo(() => {
      if (loader) {
        return loader({
          crop,
          height: heightInt,
          quality: qualityInt,
          scale: scaleInt,
          src,
          width: widthInt,
        });
      }
      return src;
    }, [crop, heightInt, qualityInt, scaleInt, src, widthInt]);

    const finalSrcset = useMemo(() => {
      return internalImageSrcSet({
        crop,
        height: heightInt,
        loader,
        quality: qualityInt,
        scale: scaleInt,
        src,
        width: widthInt,
        widths: srcSetSizes,
      });
    }, [
      crop,
      heightInt,
      qualityInt,
      scaleInt,
      src,
      widthInt,
      srcSetSizes?.join(''),
    ]);

    return (
      <>
        {priority && (
          <Head>
            <link
              rel="preload"
              as="image"
              href={finalSrc}
              {...(finalSrcset ? { imageSrcSet: finalSrcset } : null)}
              {...(sizes ? { imageSizes: sizes } : null)}
            />
          </Head>
        )}

        <picture>
          <source srcSet={finalSrcset} sizes={sizes} />
          <img
            alt={alt}
            className={`${
              fill ? 'absolute inset-0 h-full w-full object-cover' : ''
            } ${className}`}
            decoding={decoding}
            loading={priority ? 'eager' : loading}
            ref={ref}
            src={finalSrc}
            {...(fill
              ? {
                  ...(sizes ? { sizes } : null),
                }
              : { height: heightInt, width: widthInt })}
            {...props}
          />
        </picture>
      </>
    );
  }
);

ImageElement.displayName = 'ImageElement';
