import { useCallback, useEffect, useState } from 'react';
import { requestReviewsIo } from '@utilities';

export const useReviewsIo = (product) => {
  const [loaded, setLoaded] = useState(false);
  const [reviewsData, setReviewsData] = useState(null);

  const getReviewsData = useCallback(async () => {
    const skus = product?.variants?.map((variant) => variant.sku).join(';');
    if (skus) {
      const response = await requestReviewsIo({
        action: 'getProductReviews',
        data: {
          skus,
        },
      });
      if (response?.data?.stats) {
        setReviewsData(response?.data?.stats);
      }
      setLoaded(true);
    }
  }, [product?.id]);

  useEffect(() => {
    getReviewsData();
  }, []);

  return {
    average: reviewsData?.average || 0,
    count: reviewsData?.count || 0,
    loaded,
  };
};
