import React from 'react';
import { Image, Markdown } from '@snippets';
import { MadeWithModal } from './MadeWithModal';

export function MadeWithContent({
  activeTab,
  alignmentClasses,
  setActiveTab,
  tabs,
}) {
  const buildContent = (tab) => {
    const { modal, text, images } = { ...tab };
    return (
      <div className="w-full">
        <div className="w-full lg:max-w-[410px]">
          <div className="[&_ul]:text-left">
            <Markdown className="">{text}</Markdown>
          </div>
        </div>
        {modal?.buttonText && <MadeWithModal modal={modal} />}

        {images?.length > 0 && (
          <ul className="mx-auto mt-8 grid grid-cols-3 gap-5 lg:hidden">
            {images?.map((_image, index) => {
              const { heading, alt, image } = { ..._image };

              if (!image?.src) return null;

              return (
                <li key={index}>
                  <div className="relative aspect-[3/5] overflow-hidden rounded-[20px] border-2  border-[var(--accent1)]">
                    <Image alt={alt} fill sizes="33vw" src={image.src} />
                  </div>
                  <h3 className="mt-2 px-2 text-center font-helvetica text-[10px] font-semibold leading-none md:px-4 md:text-[20px] lg:text-base xl:text-[20px]">
                    {heading}
                  </h3>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="w-full lg:pr-[30px] xl:px-[50px]">
      <ul
        className={`flex flex-wrap gap-[40px] lg:flex-nowrap lg:text-left xl:gap-[60px] 2xl:gap-[80px] ${alignmentClasses}`}
      >
        {tabs?.map((tab, index) => {
          const { name } = { ...tab };

          if (!tab.name) return null;

          return (
            <li className="w-full max-lg:max-w-[620px] lg:w-auto" key={index}>
              <button
                className={`mb-5 max-lg:pointer-events-none lg:mb-11 ${
                  activeTab?.name === name
                    ? 'lg:underline lg:decoration-2 lg:underline-offset-4'
                    : ''
                }`}
                onClick={() => setActiveTab(tab)}
                type="button"
              >
                <h3 className="text-[40px] font-medium lg:text-[32px] xl:text-[40px]">
                  {name}
                </h3>
              </button>

              {/* Mobile View */}
              <div className={`flex lg:hidden ${alignmentClasses}`}>
                {buildContent(tab)}
              </div>
            </li>
          );
        })}
      </ul>

      {/* Desktop */}
      <div className="hidden lg:block">{buildContent(activeTab)}</div>
    </div>
  );
}

MadeWithContent.displayName = 'MadeWithContent';
