import { useEffect } from 'react';
import { Schema } from './ReviewsIO.schema';

export function ReviewsIO({ cms }) {
  const { title, widgetId, section } = cms;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.reviews.io/modern-widgets/ugc.js';
    script.async = true;
    script.id = 'reviews-io';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className={`${section?.fullBleed ? '' : 'px-10'} ${
        section?.verticalPadding ? 'py-contained' : ''
      }`}
      style={{
        background: section?.bgColor,
      }}
    >
      <div className={`mx-auto text-center ${section?.maxWidth}`}>
        <div className="mb-6 md:mb-8">
          {title && (
            <h2 className="text-title-h2 mb-3 md:text-[46px] md:leading-[50px]">
              {title}
            </h2>
          )}
        </div>

        {widgetId ? (
          <div
            id="reviews-io-ugc-widget"
            className="reviews-io-ugc-widget"
            widget-id={`${widgetId}`}
            data-store-name="therootist.com"
            lang="en"
          />
        ) : (
          <p className="text-red-500">Reviews IO widget id is required</p>
        )}
      </div>
    </div>
  );
}

ReviewsIO.displayName = 'ReviewsIO';
ReviewsIO.Schema = Schema;
