import { StorefrontMeta } from './StorefrontMeta';
import { StorefrontScripts } from './StorefrontScripts';

export function StorefrontHead() {
  return (
    <>
      <StorefrontScripts />

      <StorefrontMeta />
    </>
  );
}

StorefrontHead.displayName = 'StorefrontHead';
