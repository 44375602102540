import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ImageElement } from './ImageElement';

// Outlets for better image load times
// 1. Provide a custom srcSetSizes with less sizes than default (see: 'image-utils.js')
// 2. Provide sizes prop; reference: https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#resolution_switching_different_sizes
// 3. Adjust quality prop; default is 75
// 4. Preload images with priority prop (only use for images above the fold)

export const Image = forwardRef(
  (
    {
      alt,
      crop, // only applicable if width and height provided
      decoding,
      fill, // if true, parent element must be position 'relative', 'absolute', or 'fixed'
      height, // required if fill is false
      loader, // custom loader; default is Shopify cdn loader
      loading,
      priority, // if true, image will be preloaded
      quality, // 1 - 100; default 75
      scale, // 1 - 3; default 1 on fill, 2 otherwise
      sizes,
      src,
      srcSetSizes, // custom array of src set sizes; overrides the five default sizes
      width, // required if fill is false
      ...props
    },
    ref
  ) => {
    if (process.env.NODE_ENV === 'development') {
      if (!src) {
        console.error(`<Image/>: requires 'src' property`);
        return null;
      }

      if ((fill && (width || height)) || (!fill && (!width || !height))) {
        console.error(
          `<Image/>: requires either: 'fill' prop as true with undefined 'width' and 'height' props; or defined 'width' and 'height' props with 'fill' prop as false. Image: ${src}`
        );
        return null;
      }

      if (!alt) {
        console.warn(`<Image/>: 'alt' prop should not be empty. Image: ${src}`);
      }

      if (fill && !sizes) {
        console.warn(
          `<Image/>: 'sizes' prop recommended when 'fill' is true to improve page performance. Image: ${src}`
        );
      }
    }

    return (
      <ImageElement
        alt={alt}
        crop={crop}
        decoding={decoding}
        fill={fill}
        height={height}
        loader={loader}
        loading={loading}
        priority={priority}
        quality={quality}
        ref={ref}
        scale={scale}
        sizes={sizes}
        src={src}
        srcSetSizes={srcSetSizes}
        width={width}
        {...props}
      />
    );
  }
);

Image.displayName = 'Image';
Image.propTypes = {
  alt: PropTypes.string,
  crop: PropTypes.oneOf(['center', 'top', 'bottom', 'left', 'right']),
  decoding: PropTypes.oneOf(['async', 'auto', 'sync']),
  fill: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loader: PropTypes.func,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  priority: PropTypes.bool,
  quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scale: PropTypes.oneOf(['1', 1, '2', 2, '3', 3]),
  sizes: PropTypes.string,
  src: PropTypes.string,
  srcSetSizes: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
