import React from 'react';
import { Image } from '@snippets';

export function SpinningImage({ image }) {
  const { imageBackground, imageStacked, positionDesktop, positionMobile } = {
    ...image,
  };
  const positionClasses = `${positionMobile} ${positionDesktop}`;

  if (!imageBackground?.image?.src && !imageStacked?.image?.src) return null;

  return (
    <div className={`absolute flex h-full w-full ${positionClasses}`}>
      {/* desktop */}
      <div
        className="absolute z-10 m-4 hidden items-center justify-center lg:flex"
        style={{
          width: imageBackground?.widthDesktop || '80px',
          height: imageBackground?.heightDesktop || '80px',
        }}
      >
        {imageBackground?.image?.src && (
          <Image
            alt={imageBackground?.image?.alt}
            fill
            src={imageBackground.image.src}
            style={
              imageBackground?.enabledRotationAnimation
                ? {
                    animation: `rotating ${
                      imageBackground?.animationSpeed || 10
                    }s infinite linear`,
                  }
                : {}
            }
          />
        )}
        <div
          className="absolute"
          style={{
            width: imageStacked?.widthDesktop || '64px',
            height: imageStacked?.heightDesktop || '64px',
          }}
        >
          {imageStacked?.image?.src && (
            <Image
              alt={imageStacked?.image?.alt}
              fill
              src={imageStacked.image.src}
              style={
                imageStacked?.enabledRotationAnimation
                  ? {
                      animation: `rotating ${
                        imageStacked?.animationSpeed || 10
                      }s infinite linear`,
                    }
                  : {}
              }
            />
          )}
        </div>
      </div>

      {/* mobile */}
      <div
        className="absolute z-10 m-4 flex items-center justify-center lg:hidden"
        style={{
          width: imageBackground?.widthMobile || '64px',
          height: imageBackground?.heightMobile || '64px',
        }}
      >
        {imageBackground?.image?.src && (
          <Image
            alt={imageBackground?.image?.alt}
            fill
            sizes="100vw"
            src={imageBackground.image.src}
            style={
              imageBackground?.enabledRotationAnimation
                ? {
                    animation: `rotating ${
                      imageBackground?.animationSpeed || 10
                    }s infinite linear`,
                  }
                : {}
            }
          />
        )}
        <div
          className="absolute"
          style={{
            width: imageStacked?.widthMobile || '40px',
            height: imageStacked?.heightMobile || '40px',
          }}
        >
          {imageStacked?.image?.src && (
            <Image
              alt={imageStacked?.image?.alt}
              fill
              sizes="100vw"
              src={imageStacked.image.src}
              style={
                imageStacked?.enabledRotationAnimation
                  ? {
                      animation: `rotating ${
                        imageStacked?.animationSpeed || 10
                      }s infinite linear`,
                    }
                  : {}
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

SpinningImage.displayName = 'SpinningImage';
