import Script from 'next/script';

export function MainSchemaMarkup({ pageUrl }) {
  return (
    <Script
      id="schema-markup-org"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
          url: pageUrl,
          logo: process.env.NEXT_PUBLIC_SITE_LOGO || '',
        }),
      }}
    />
  );
}

MainSchemaMarkup.displayName = 'MainSchemaMarkup';
