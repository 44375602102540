import Head from 'next/head';
import { useSettings } from '@backpackjs/storefront';

export function StorefrontMeta() {
  const settings = useSettings();
  const { metaThemeColor } = { ...settings?.theme };

  return (
    <Head>
      {/* fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />

      {/* favicon */}
      <link rel="shortcut icon" href="/icons/favicon.svg" />

      {/* additional open graph */}
      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />

      {/* pwa (progressive web app) */}
      <meta name="theme-color" content={metaThemeColor || '#000000'} />

      <link rel="manifest" href="/manifest.json" />

      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta
        name="application-name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta
        name="google-site-verification"
        content="google6dc711d85778c7ac.html"
      />
    </Head>
  );
}

StorefrontMeta.displayName = 'StorefrontMeta';
