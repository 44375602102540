import { useSettings } from '@backpackjs/storefront';

export function usePromobar() {
  const settings = useSettings();
  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    !!promobar && (!promobar.enabled || !promobar.messages?.length);
  const mainClassName = promobarDisabled
    ? 'pt-[var(--header-height)]'
    : 'pt-[calc(var(--header-height)+var(--promobar-height))]';

  return { promobarDisabled, mainClassName };
}
