import { ReviewStars } from '@snippets';
import { useReviewsIo } from '@hooks';

export function ProductStars({ product, color }) {
  const { average, count, loaded } = useReviewsIo(product);

  if (!loaded) return null;

  return (
    <div
      data-comp={ProductStars.displayName}
      className="flex min-h-[1rem] flex-wrap items-center gap-1"
    >
      <ReviewStars rating={average} size="small" color={color} />

      <p className="text-2xs text-mediumDarkGray underline underline-offset-[3px]">
        ({count} Reviews)
      </p>
    </div>
  );
}

ProductStars.displayName = 'ProductStars';
