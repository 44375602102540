export const menu = {
  label: 'Menu',
  name: 'menu',
  component: 'group',
  description: 'Menu items, logo position, products slider, links',
  fields: [
    {
      label: 'Menu Items',
      name: 'menuItems',
      component: 'group-list',
      itemProps: {
        label: '{{item.menuItem.text}}',
      },
      fields: [
        {
          label: 'Menu Item',
          name: 'menuItem',
          component: 'link',
        },
        {
          label: 'Submenu Links',
          name: 'links',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
          defaultItem: {},
        },
        {
          label: 'Submenu Button',
          name: 'mainLink',
          component: 'link',
        },
        {
          label: 'Submenu Images',
          name: 'imageLinks',
          component: 'group-list',
          itemProps: {
            label: '{{item.caption}}',
          },
          fields: [
            {
              label: 'Image Alt',
              name: 'alt',
              component: 'text',
              description: 'Brief description of image',
            },
            {
              label: 'Image',
              name: 'image',
              component: 'image',
            },
            {
              label: 'Caption',
              name: 'caption',
              component: 'text',
            },
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
          defaultItem: {},
        },
      ],
      defaultItem: {
        menuItem: { text: 'Shop', url: '/collections/all' },
      },
    },
    {
      label: 'Logo Position (desktop)',
      name: 'logoPositionDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
      ],
      defaultValue: 'left',
    },
    {
      label: 'Products Slider',
      name: 'productsSlider',
      description: 'Visible only in menu sidebar',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Products',
          name: 'products',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          fields: [
            {
              label: 'Product',
              name: 'product',
              component: 'productSearch',
            },
          ],
        },
      ],
    },
    {
      label: 'Addtional Links',
      name: 'links',
      description: 'Visible only in menu sidebar',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
    },
  ],
};
