import { useCallback, useMemo, useState } from 'react';
import { useCartAddItem } from '@backpackjs/storefront';

import { Spinner } from '@snippets';
import { useGlobalContext } from '@contexts';

export function QuickShopOption({
  inventory,
  inventoryFetched,
  optionName,
  selectedProduct,
  selectedVariant,
  value,
}) {
  const {
    actions: { openCart },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();

  const [isAdding, setIsAdding] = useState(false);

  const variantToAdd = useMemo(() => {
    return selectedProduct.variants?.find((variant) => {
      const color = variant.selectedOptionsMap?.Color;
      return (
        variant.selectedOptionsMap[optionName] === value &&
        (!color || color === selectedVariant.selectedOptionsMap?.Color)
      );
    });
  }, [optionName, selectedProduct.id, selectedVariant.id, value]);

  const variantInventory = inventory?.variants?.[variantToAdd?.id];
  const variantIsSoldOut =
    inventoryFetched && !variantInventory?.availableForSale;
  // const variantIsPreorder =
  //   !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const disabled = !inventoryFetched || !variantToAdd;

  const handleAddToCart = useCallback(async () => {
    if (!variantToAdd?.id || !inventoryFetched || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: variantToAdd.id,
      quantity: 1,
    });
    setIsAdding(false);
    openCart();
  }, [variantToAdd?.id, inventoryFetched, isAdding]);

  const validClass =
    disabled || variantIsSoldOut
      ? 'cursor-not-allowed'
      : 'hover:bg-[var(--primary-btn-hover-bg-color)] hover:text-[var(--primary-btn-hover-text-color)] text-[var(--primary-btn-text-color)]';
  const unavailableClass = variantIsSoldOut
    ? 'after:h-px after:w-[150%] after:rotate-[135deg] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-mediumGray text-mediumGray bg-disabled overflow-hidden'
    : '';

  return (
    <button
      aria-label={value}
      className={`group/option relative flex h-full w-full items-center justify-center whitespace-nowrap text-center text-sm transition ${validClass} ${unavailableClass}`}
      disabled={disabled || variantIsSoldOut}
      onClick={handleAddToCart}
      type="button"
    >
      {isAdding ? (
        <div className="text-mediumGray group-hover/option:text-[var(--primary-btn-hover-text-color)]">
          <Spinner width="20" />
        </div>
      ) : (
        value
      )}
    </button>
  );
}

QuickShopOption.displayName = 'QuickShopOption';
