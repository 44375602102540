import { useCartCount, useCustomer, useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import { Link, Svg } from '@snippets';
import { useGlobalContext } from '@contexts';

export function Menu({
  handleOpenSidebar,
  handleMenuDrawerClose,
  handleMenuHoverIn,
  handleMenuHoverOut,
  menuDrawerContent,
  transparentBGEnabled,
}) {
  const { pathname } = useRouter();
  const customer = useCustomer();
  const {
    actions: { openCart, openSearch },
  } = useGlobalContext();
  const cartCount = useCartCount();
  const settings = useSettings();
  const { logoPositionDesktop, menuItems } = {
    ...settings?.header?.menu,
  };
  const gridColsClassDesktop =
    logoPositionDesktop === 'center'
      ? 'lg:grid-cols-[1fr_auto_1fr]'
      : 'lg:grid-cols-[auto_1fr_auto]';
  const logoOrderClassDesktop =
    logoPositionDesktop === 'center' ? 'lg:order-2' : 'lg:order-1';
  const menuOrderClassDesktop =
    logoPositionDesktop === 'center' ? 'lg:order-1' : 'lg:order-2';

  return (
    <div
      data-comp={Menu.displayName}
      className={`px-contained relative z-[1] grid flex-1 grid-cols-[1fr_auto_1fr] gap-4 transition md:gap-6 ${gridColsClassDesktop} ${
        transparentBGEnabled
          ? 'bg-transparent'
          : 'border-b border-b-border bg-background'
      }`}
    >
      <div className={`order-2 flex items-center ${logoOrderClassDesktop}`}>
        <Link
          aria-label="Go to homepage"
          className={`relative w-20 ${
            transparentBGEnabled
              ? 'lg:h-[72px] lg:w-[105px]'
              : pathname === '/'
              ? 'lg:w-[105px]'
              : ''
          }`}
          href="/"
        >
          <Svg
            className={`text-accent1 transition-all duration-700 ${
              transparentBGEnabled
                ? 'left-0 top-0 w-16 lg:absolute lg:w-[130px]'
                : 'w-16'
            }`}
            src="/svgs/logo.svg#logo"
            title="Storefront logo"
            viewBox="0 0 720.76 630.84"
          />
        </Link>
      </div>

      <div className={`order-1 flex items-center ${menuOrderClassDesktop}`}>
        <nav className="hidden h-full lg:flex">
          <ul className="flex">
            {menuItems?.map((item, index) => {
              const isHovered =
                item.menuItem?.text === menuDrawerContent?.menuItem?.text;

              return (
                <li key={index} className="flex">
                  {item.menuItem?.url ? (
                    <Link
                      aria-label={item.menuItem?.text}
                      className="group relative flex items-center px-4 transition"
                      href={item.menuItem?.url}
                      onClick={handleMenuDrawerClose}
                      onMouseEnter={() => handleMenuHoverIn(index)}
                      onMouseLeave={handleMenuHoverOut}
                    >
                      <p className="text-nav-main">{item.menuItem?.text}</p>

                      <div
                        className={`after:w-fill absolute left-0 top-[calc(100%_-_2px)] h-[3px] w-full origin-center scale-0 border-t-2 border-t-primary bg-transparent transition group-hover:scale-100 ${
                          isHovered ? 'scale-100' : 'scale-0'
                        }`}
                      />
                    </Link>
                  ) : (
                    <div
                      aria-label={item.menuItem?.text}
                      className="group relative flex cursor-pointer select-none items-center px-4 transition"
                      onMouseEnter={() => handleMenuHoverIn(index)}
                      onMouseLeave={handleMenuHoverOut}
                    >
                      <p className="text-nav-main">{item.menuItem?.text}</p>

                      <div
                        className={`after:w-fill absolute left-0 top-[calc(100%_-_2px)] h-[3px] w-full origin-center scale-0 border-t-2 border-t-primary bg-transparent transition group-hover:scale-100 ${
                          isHovered ? 'scale-100' : 'scale-0'
                        }`}
                      />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex items-center gap-4">
          <button
            aria-label="Open menu"
            className="w-5 lg:hidden"
            onClick={handleOpenSidebar}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/menu.svg#menu"
              title="Menu"
              viewBox="0 0 24 24"
            />
          </button>

          <button
            aria-label="Open search"
            className="block w-5 md:hidden"
            onClick={openSearch}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/search.svg#search"
              title="Search"
              viewBox="0 0 24 24"
            />
          </button>
        </div>
      </div>

      <div className="order-3 flex items-center justify-end gap-4 md:gap-5">
        <button
          aria-label="Open search"
          className="hidden w-5 md:block"
          onClick={openSearch}
          type="button"
        >
          <Svg
            className="w-full text-text"
            src="/svgs/search.svg#search"
            title="Search"
            viewBox="0 0 24 24"
          />
        </button>

        <Link
          aria-label="Go to account page"
          href={customer ? '/account/orders' : '/account/login'}
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/account.svg#account"
            title="Account"
            viewBox="0 0 24 24"
          />
        </Link>

        <div className="relative flex items-center">
          <button
            aria-label="Open cart"
            className="w-5"
            onClick={openCart}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/cart.svg#cart"
              title="Cart"
              viewBox="0 0 24 24"
            />
          </button>

          <p className="text-label-sm w-4 whitespace-nowrap pl-px font-bold">
            ({cartCount || 0})
          </p>
        </div>
      </div>
    </div>
  );
}

Menu.displayName = 'Menu';
