import { useInView } from 'react-intersection-observer';

import { HalfHeroVideo } from './HalfHeroVideo';
import { Image } from '../../snippets';

export function HalfHeroMedia({
  aboveTheFold,
  media,
  videoAlt,
  isActiveSlide,
  isFirstSlide,
}) {
  const { image, video } = { ...media };

  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const isVisible =
    (aboveTheFold && isActiveSlide) ||
    (!aboveTheFold && isActiveSlide && inView);

  return (
    <div className="absolute inset-0 h-full w-full" ref={ref}>
      <div className="relative h-full w-full overflow-hidden md:hidden">
        {video?.srcMobile && (
          <HalfHeroVideo
            isVisible={isVisible}
            autoplay={video.autoplay}
            posterSrc={video.posterMobile?.src}
            sound={video.sound}
            videoAlt={videoAlt}
            videoSrc={video.srcMobile}
          />
        )}

        {image?.imageMobile?.src && !video?.srcMobile && (
          <Image
            alt={image.alt}
            className={`${image.positionMobile}`}
            fill
            sizes="100vw"
            priority={aboveTheFold && isFirstSlide}
            src={image.imageMobile.src}
          />
        )}
      </div>

      <div className="relative hidden h-full w-full overflow-hidden md:block">
        {video?.srcDesktop && (
          <HalfHeroVideo
            isVisible={isVisible}
            autoplay={video.autoplay}
            posterSrc={video.posterDesktop?.src}
            sound={video.sound}
            videoAlt={videoAlt}
            videoSrc={video.srcDesktop}
          />
        )}

        {image?.imageDesktop?.src && !video?.srcDesktop && (
          <Image
            alt={image.alt}
            className={`${image.positionDesktop}`}
            fill
            priority={aboveTheFold && isFirstSlide}
            sizes="50vw"
            src={image.imageDesktop.src}
          />
        )}
      </div>
    </div>
  );
}

HalfHeroMedia.displayName = 'HalfHeroMedia';
