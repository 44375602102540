import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { Image } from '@snippets';

export function Badges({
  className,
  isDraft, // in customizer, badge added to indicate product is draft
  tags,
}) {
  const settings = useSettings();
  const { badgeColors } = { ...settings?.product?.badges };

  const badgeColorsMap = useMemo(() => {
    if (!badgeColors) return {};
    return badgeColors.reduce((acc, badge) => {
      return { ...acc, [badge.tag?.trim()]: badge };
    }, {});
  }, [badgeColors]);

  const badges = useMemo(() => {
    return tags.reduce(
      (acc, tag) => {
        if (tag.startsWith('badge::')) {
          const value = tag.split('badge::')[1]?.trim();
          if (!value) return acc;
          return [...acc, value];
        }
        return acc;
      },
      isDraft ? ['Draft'] : []
    );
  }, [isDraft, tags]);

  return (
    <div
      data-comp={Badges.displayName}
      className={`text-label flex flex-wrap gap-2.5 xs:gap-3 [&_div]:rounded-md [&_div]:px-2 [&_div]:py-1 ${className}`}
    >
      {badges?.map((badge, index) => {
        const image = badgeColors?.find(
          (_badge) => _badge.tag === badge
        )?.image;

        const { imageBackground, imageStacked } = { ...image };

        if (imageBackground?.image?.src || imageStacked?.image?.src) {
          return (
            <div className="relative flex h-14 w-14 items-center justify-center lg:h-20 lg:w-20">
              {imageBackground?.image?.src && (
                <Image
                  alt={imageBackground?.image?.alt}
                  fill
                  sizes="100vw"
                  src={imageBackground.image.src}
                  style={
                    imageBackground?.enabledRotationAnimation
                      ? {
                          animation: `rotating ${
                            imageBackground?.animationSpeed || 10
                          }s infinite linear`,
                        }
                      : {}
                  }
                />
              )}
              {imageStacked?.image?.src && (
                <div className="absolute h-10 w-10 lg:h-16 lg:w-16">
                  <Image
                    alt={imageStacked?.image?.alt}
                    fill
                    sizes="100vw"
                    src={imageStacked.image.src}
                    style={
                      imageStacked?.enabledRotationAnimation
                        ? {
                            animation: `rotating ${
                              imageStacked?.animationSpeed || 10
                            }s infinite linear`,
                          }
                        : {}
                    }
                  />
                </div>
              )}
            </div>
          );
        }

        return (
          <div
            key={index}
            style={{
              background: badgeColorsMap[badge]?.bgColor || 'var(--black)',
              color: badgeColorsMap[badge]?.textColor || 'var(--white)',
            }}
          >
            {badge}
          </div>
        );
      })}
    </div>
  );
}

Badges.displayName = 'Badges';
Badges.defaultProps = {
  className: '',
  isDraft: false,
  tags: [],
};
Badges.propTypes = {
  className: PropTypes.string,
  isDraft: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
};
