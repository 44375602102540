import { useCartItems, useSettings } from '@backpackjs/storefront';

import { CartEmpty } from './CartEmpty';
import { CartHeader } from './CartHeader';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { CartUpsell } from './CartUpsell';
import { FreeShippingMeter } from './FreeShippingMeter';
import { useGlobalContext } from '../../contexts';

export function Cart() {
  const cartItems = useCartItems();
  const settings = useSettings();
  const {
    state: { cartOpen },
    actions: { closeCart },
  } = useGlobalContext();

  const enabledUpsell = settings?.cart?.upsell?.enabled;

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-background transition md:max-w-[var(--sidebar-width)] ${
        cartOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
    >
      {cartOpen && (
        <>
          <CartHeader closeCart={closeCart} heading={settings?.cart?.heading} />

          <FreeShippingMeter />

          <ul className="scrollbar-hide relative flex-1 overflow-y-auto">
            {cartItems?.length ? (
              cartItems.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="border-b border-b-border last:border-none"
                  >
                    <CartItem item={item} closeCart={closeCart} />
                  </li>
                );
              })
            ) : (
              <CartEmpty closeCart={closeCart} />
            )}
          </ul>

          {enabledUpsell && <CartUpsell closeCart={closeCart} />}

          <CartTotals />
        </>
      )}
    </aside>
  );
}

Cart.displayName = 'Cart';
