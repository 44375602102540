import React from 'react';
import PropTypes from 'prop-types';

import { Image, Link, Svg, Markdown } from '@snippets';

export function ThreeTilesTile({ aspectRatio, item, textColor }) {
  return (
    <div
      data-comp={ThreeTilesTile.displayName}
      className="w-full"
      style={{ color: textColor }}
    >
      <div className={`relative mb-4 bg-offWhite ${aspectRatio}`}>
        <Link
          aria-label={item.heading}
          href={item.link?.url}
          newTab={item.link?.newTab}
          tabIndex="-1"
          type={item.link?.type}
        >
          {item.image?.src && (
            <Image
              alt={item.alt}
              className={`${item.position}`}
              fill
              sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
              src={item.image.src}
            />
          )}
        </Link>
      </div>

      <div className="inset-0 flex h-full w-full flex-col items-start gap-4">
        <Link
          aria-label={item.heading}
          href={item.link?.url}
          newTab={item.link?.newTab}
          type={item.link?.type}
        >
          <div className="group flex">
            <h2 className="text-xl lg:text-2xl">{item.heading}</h2>

            <span className="ml-[0.75rem] block w-[1.25rem] transition-transform lg:group-hover:translate-x-2">
              <Svg
                src="/svgs/arrow-right.svg#arrow-right"
                title="Arrow"
                viewBox="0 0 24 24"
              />
            </span>
          </div>
        </Link>

        {/* [&_li::marker]:content-['✔_'] */}
        {item.description && (
          <div className="[&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_li::marker]:text-primary [&_p]:text-base [&_ul]:pl-5">
            <Markdown>{item.description}</Markdown>
          </div>
        )}

        {item.link?.text && (
          <div>
            <Link
              aria-label={item.link.text}
              className="text-link-sm text-underline no-underline"
              href={item.link.url}
              newTab={item.link.newTab}
              tabIndex="-1"
              type={item.link.type}
            >
              {item.link.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

ThreeTilesTile.displayName = 'ThreeTilesTile';
ThreeTilesTile.propTypes = {
  aspectRatio: PropTypes.string,
  item: PropTypes.object,
  textColor: PropTypes.string,
};
