import { Fragment } from 'react';
import { Image, Link } from '@snippets';

export function ImageTile({ content, item }) {
  const {
    alignmentMobile,
    alignmentDesktop,
    positionMobile,
    positionDesktop,
    colorMobile,
    colorDesktop,
    maxWidthDesktop,
    maxWidthMobile,
  } = { ...content };

  const firstLink = item.buttons?.[0]?.link;
  const secondLink = item.buttons?.[1]?.link;

  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  const positionClasses = `${positionMobile} ${positionDesktop}`;
  const textColorClasses = `${colorMobile} ${colorDesktop}`;
  const maxWidthContentClasses = `${maxWidthMobile} ${maxWidthDesktop}`;
  return (
    <Link
      data-comp={ImageTile.displayName}
      aria-label={firstLink?.text}
      className="w-full"
      href={content?.clickableImage ? firstLink?.url : ''}
      newTab={firstLink?.newTab}
      type={firstLink?.type}
    >
      <div
        className={`${item?.aspectMobile} relative overflow-hidden md:hidden`}
      >
        {item?.imageMobile?.src && (
          <Image
            alt={item.alt}
            className={`${item.positionMobile}`}
            fill
            sizes="(min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
            src={item.imageMobile.src}
          />
        )}
      </div>

      <div
        className={`${item?.aspectDesktop} relative hidden overflow-hidden md:block`}
      >
        {item?.imageDesktop?.src && (
          <Image
            alt={item.alt}
            className={`${item.positionDesktop}`}
            fill
            sizes="(min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
            src={item.imageDesktop.src}
          />
        )}
      </div>

      <div
        className={`px-contained py-contained pointer-events-none absolute inset-0 flex h-full w-full ${
          content?.darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : ''
        } ${positionClasses}`}
      >
        <div
          className={`${alignmentClasses} ${maxWidthContentClasses} pointer-events-auto flex flex-col`}
        >
          {item.heading && (
            <h3 className={`text-title-h3 ${textColorClasses}`}>
              {item.heading}
            </h3>
          )}
          {item.description && (
            <p className={`text-body mt-2 ${textColorClasses}`}>
              {item.description}
            </p>
          )}

          {!content?.hideButtons && (firstLink?.text || secondLink?.text) && (
            <div className="mt-5 flex flex-wrap justify-center gap-3">
              {item.buttons.slice(0, 2).map(({ link }, index) => {
                // hide second button if clickable image is enabled
                if (content?.clickableImage && index > 0) return null;

                return link?.text ? (
                  <Fragment key={index}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      aria-label={link.text}
                      className={`${
                        index === 1
                          ? content?.secondaryButtonStyle
                          : content?.primaryButtonStyle
                      } ${
                        content?.primaryButtonStyle.includes('text')
                          ? textColorClasses
                          : null
                      }`}
                      href={!content?.clickableImage ? link.url : ''}
                      newTab={link.newTab}
                      type={link.type}
                    >
                      {link.text}
                    </Link>
                  </Fragment>
                ) : null;
              })}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

ImageTile.displayName = 'ImageTile';
