import { useCallback } from 'react';
import { Markdown } from '@snippets';
import { useGlobalContext } from '@contexts';

export function MadeWithModal({ modal }) {
  const { buttonStyle, buttonText, buttonTextColor, heading, subtext } = {
    ...modal,
  };

  const {
    actions: { openModal },
  } = useGlobalContext();

  const handleClick = useCallback(() => {
    if (!buttonText) return;
    openModal(
      <div className="flex flex-col">
        <h2 className="text-title-h3 mb-6 text-center">{heading}</h2>
        {subtext && (
          <div className="mt-6 [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <Markdown>{subtext}</Markdown>
          </div>
        )}
      </div>
    );
  }, [buttonText]);

  return (
    <div className="mt-5">
      <button
        className={`${buttonStyle} btn-text-link-currentColor`}
        data-comp={MadeWithModal.displayName}
        onClick={handleClick}
        style={{
          color: buttonTextColor || 'var(--primary)',
        }}
        type="button"
      >
        {buttonText}
      </button>
    </div>
  );
}

MadeWithModal.displayName = 'MadeWithModal';
