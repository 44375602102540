import { useState } from 'react';
import { Schema } from './MadeWith.schema';
import { MadeWithContent } from './MadeWithContent';
import { MadeWithImages } from './MadeWithImages';

export function MadeWith({ cms }) {
  const { content, section, tab } = cms;
  const { tabs } = { ...tab };
  const [activeTab, setActiveTab] = useState(tabs?.[0]);

  const { alignmentMobile } = {
    ...content,
  };
  const alignmentClasses = `${alignmentMobile}`;

  return (
    <div
      className="px-4 py-8 md:px-16 md:py-[100px]"
      style={{ background: section?.bgColor, color: section?.color }}
    >
      <div className="relative mx-auto flex max-w-[1320px]">
        <div className="flex w-full lg:w-[52%]">
          <MadeWithContent
            activeTab={activeTab}
            alignmentClasses={alignmentClasses}
            setActiveTab={setActiveTab}
            tabs={tabs}
          />
        </div>

        {/* Desktop Only */}
        <div className="relative hidden w-full lg:block lg:w-[48%]">
          <MadeWithImages activeTab={activeTab} tabs={tabs} />
        </div>
      </div>
    </div>
  );
}

MadeWith.displayName = 'MadeWith';
MadeWith.Schema = Schema;
