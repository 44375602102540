import { useState } from 'react';

import { usePromobar } from '@hooks';
import { Menu } from './Menu';
import { MenuDrawer } from './MenuDrawer';
import { MenuSidebar } from './MenuSidebar';
import { Promobar } from './Promobar';
import { RotatingBar } from './RotatingBar';
import { useMenuDrawer } from './useMenuDrawer';
import { useMenuSidebar } from './useMenuSidebar';
import { useHeader } from './useHeader';

export function Header() {
  const {
    state: { menuDrawerContent, menuDrawerIndex },
    actions: {
      handleMenuDrawerClose,
      handleMenuDrawerStayOpen,
      handleMenuHoverIn,
      handleMenuHoverOut,
    },
  } = useMenuDrawer();
  const { promobarDisabled } = usePromobar();

  const {
    state: { menuSidebarOpen, nestedSidebarContent },
    actions: { handleCloseSidebar, handleNestedSidebar, handleOpenSidebar },
  } = useMenuSidebar();

  const { isLocked, setTransparentBGEnabled, transparentBGEnabled } = useHeader(
    { menuDrawerIndex, menuSidebarOpen }
  );

  const [promobarHidden, setPromobarHidden] = useState(false);

  return (
    <header
      className={`fixed left-0 right-0 top-0 z-20 flex flex-col transition-[height] duration-300 ease-out ${
        promobarHidden || promobarDisabled
          ? 'h-[var(--header-height)]'
          : 'h-[calc(var(--header-height)+var(--promobar-height))]'
      }`}
      onMouseEnter={() => !isLocked && setTransparentBGEnabled(false)}
      onMouseLeave={() =>
        !isLocked && setTimeout(() => setTransparentBGEnabled(true), 100)
      }
    >
      <RotatingBar
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        setPromobarHidden={setPromobarHidden}
      />
      <Promobar
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        setPromobarHidden={setPromobarHidden}
      />

      <Menu
        handleOpenSidebar={handleOpenSidebar}
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuHoverIn={handleMenuHoverIn}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
        transparentBGEnabled={transparentBGEnabled}
      />

      <MenuDrawer
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuDrawerStayOpen={handleMenuDrawerStayOpen}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
        transparentBGEnabled={transparentBGEnabled}
      />

      <MenuSidebar
        handleCloseSidebar={handleCloseSidebar}
        handleNestedSidebar={handleNestedSidebar}
        menuSidebarOpen={menuSidebarOpen}
        nestedSidebarContent={nestedSidebarContent}
      />
    </header>
  );
}

Header.displayName = 'Header';
