import { TEXT_COLORS, COLORS } from '@settings/common';

export const Schema = () => {
  return {
    category: 'Text',
    label: 'Scrolling Banner',
    key: 'scrolling-banner',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/scrolling-banner-preview.png?v=1701209707',
    fields: [
      {
        label: 'Messages',
        name: 'messages',
        component: 'group-list',
        itemProps: {
          label: '{{item.text}}',
        },
        fields: [
          {
            label: 'Text',
            name: 'text',
            component: 'text',
            defaultValue: 'Add message here ...',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
            defaultValue: {
              text: '',
              url: '',
            },
          },
          {
            label: 'Enable Link',
            name: 'enableLink',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
        ],
      },
      {
        label: 'Text Multiplier',
        name: 'textMultiplier',
        description:
          'creates duplicates of text above. Needed to make short text longer with spacing consistent spacing inbetween if spacing between messages is enabled',
        component: 'number',
        default: 2,
      },
      {
        label: 'Font Family',
        name: 'fontFamily',
        component: 'select',
        options: [
          { label: 'Feature Display', value: 'font-feature' },
          { label: 'Helvetica Neue', value: 'font-helvetica' },
        ],
        defaultValue: 'font-feature',
      },
      {
        label: 'Font Size',
        name: 'fontSize',
        component: 'select',
        options: [
          { label: 'Small', value: 'text-[30px] py-[10px]' },
          { label: 'Medium', value: 'text-[70px] py-[29px]' },
          { label: 'Large', value: 'text-[123px] py-[34px]' },
        ],
        defaultValue: 'text-[30px] py-[17px]',
      },
      {
        label: 'Text Color',
        name: 'color',
        component: 'select',
        options: TEXT_COLORS.mobile,
        defaultValue: 'text-[var(--white)]',
      },
      {
        label: 'Background Color',
        name: 'bgColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--accent1)',
      },
      {
        label: 'Scrolling Speed',
        name: 'scrollingSpeed',
        description:
          'scrolling speed in seconds. The higher the number, the slower the scroll movement',
        component: 'number',
        default: 30,
      },
      {
        label: 'Spacing Settings',
        name: 'spacing',
        component: 'group',
        fields: [
          {
            label: 'Enable Spacing Between Messages',
            name: 'enableSpacingBetweenMessages',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Width (px) between messages',
            name: 'width',
            component: 'number',
            default: 50,
          },
        ],
      },
    ],
  };
};
