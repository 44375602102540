import { COLORS } from '../../settings/common';

export const Schema = () => {
  return {
    label: 'Reviews IO UCG',
    key: 'reviews-io',
    category: 'Media',
    fields: [
      {
        label: 'Widget id',
        name: 'widgetId',
        component: 'text',
        default: 'FXV408dTfCpWYsbD',
      },
      {
        label: 'Title',
        name: 'title',
        component: 'text',
        default: 'Reviews',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'background color, full bleed, max content width, vertical padding',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: true,
          fullBleed: false,
          verticalPadding: false,
        },
      },
    ],
    defaultValue: {
      widgetId: 'FXV408dTfCpWYsbD',
      title: 'Reviews',
      section: {
        bgColor: 'white',
        fullBleed: false,
        maxWidth: 'max-w-[60rem]',
        verticalPadding: false,
      },
    },
  };
};
