import {
  useCartItems,
  useCartTotals,
  useCartCount,
  useCartCheckout,
  useSettings,
} from '@backpackjs/storefront';

export function CartTotals({ isCartPage }) {
  const cartItems = useCartItems();
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const { cartCheckout } = useCartCheckout();
  const settings = useSettings();
  const { checkoutText, subtext, subtotalText } = { ...settings?.cart?.totals };
  const hasSubscription = cartItems.some((item) => item.sellingPlanAllocation);

  return (
    <div
      data-comp={CartTotals.displayName}
      className={`flex-col gap-4 border-t border-t-border p-4 ${
        cartCount ? 'flex' : 'hidden'
      }`}
    >
      <div>
        <div className="flex justify-between">
          <p className="text-title-h5">{subtotalText || 'Subtotal'}</p>
          <p>
            $
            {parseFloat(cartTotals?.total || '0')
              .toFixed(2)
              .replace('.00', '')}
          </p>
        </div>

        {subtext && <p className="mt-1 text-xs">{subtext}</p>}
      </div>

      {cartTotals?.total && !hasSubscription && (
        <catch-callout
          theme="light-mono"
          price={cartTotals.total * 100}
          page-type={isCartPage ? 'cart' : 'mini-cart'}
          border-style="none-no-padding"
          style={{
            fontSize: '12px',
            fontFamily: 'Helvetica Neue, sans-serif',
            margin: '-5px auto 0',
          }}
        />
      )}

      <button
        aria-label="Go to checkout page"
        className="btn-primary w-full"
        onClick={cartCheckout}
        type="button"
      >
        {checkoutText || 'Checkout'}
      </button>
    </div>
  );
}

CartTotals.displayName = 'CartTotals';
