export function Schema() {
  return {
    category: 'Product',
    label: 'PDP Modal',
    key: 'pdp-modal',
    fields: [
      {
        label: 'Button Text',
        name: 'buttonText',
        component: 'text',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Image Alt',
        name: 'alt',
        component: 'text',
        description: 'Brief description of image',
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'markdown',
      },
    ],
  };
}
