import { useMemo } from 'react';

import { Image, Link, Spinner, Svg, QuantitySelector } from '@snippets';
import { useCartItem } from './useCartItem';
import { useCartItemImage } from './useCartItemImage';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItem({ closeCart, item }) {
  const { quantity, variant, sellingPlanAllocation } = item;
  const sellingPlan = sellingPlanAllocation?.sellingPlan;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ item });

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const image = useCartItemImage({ item });

  const url = useMemo(() => {
    return `/products/${variant.product.handle}?variant=${variant.id
      .split('/')
      .pop()}`;
  }, [variant.id]);

  return (
    <div
      data-comp={CartItem.displayName}
      className="relative grid grid-cols-[auto_1fr] items-center gap-3 p-4 "
    >
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        {image?.src && (
          <Image
            alt={variant.product.title}
            className="bg-offWhite"
            crop="center"
            height={Math.floor(88 / (image.width / image.height))}
            src={image.src}
            width="88"
          />
        )}
      </Link>

      <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
        <div className="relative pr-6">
          <Link
            aria-label={`View ${variant.product.title}`}
            href={url}
            onClick={closeCart}
          >
            <h3 className="text-title-h5">{variant.product.title}</h3>
          </Link>

          {variant.title !== 'Default Title' && (
            <p className="mt-1 text-sm text-mediumGray">{variant.title}</p>
          )}

          {sellingPlan?.name && (
            <p className="mt-1 text-sm text-mediumGray">{sellingPlan?.name}</p>
          )}

          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="absolute right-0 top-0 w-3"
            onClick={handleRemove}
            type="button"
          >
            <Svg
              src="/svgs/close.svg#close"
              title="Close"
              viewBox="0 0 24 24"
            />
          </button>
        </div>

        <div className="flex items-center justify-between gap-3">
          <QuantitySelector
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            isUpdating={isUpdatingItem}
            productTitle={variant.product.title}
            quantity={quantity}
          />

          <div className="flex flex-1 flex-wrap justify-end gap-x-2">
            {compareAtPrice && (
              <p className="text-body text-mediumGray line-through">
                {compareAtPrice}
              </p>
            )}
            <p
              className={`${
                compareAtPrice ? 'text-[var(--sale)]' : 'text-text'
              } text-body`}
            >
              {price}
            </p>
          </div>
        </div>
      </div>

      {isRemovingItem && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
          <Spinner width="20" />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
