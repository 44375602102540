import { useState, useEffect } from 'react';
import { useRouter, useSettings } from '@backpackjs/storefront';

export const useHeader = ({ menuDrawerIndex, menuSidebarOpen }) => {
  const settings = useSettings();
  const router = useRouter();

  const { enableTransparentBackground: enable } = { ...settings?.header };

  const [isHomepage, setIsHomepage] = useState(false);
  const [scrollIsActive, setScrollIsActive] = useState(false);
  const [transparentBGEnabled, setTransparentBGEnabled] = useState(false);

  const onRountChangeComplete = (url) => {
    if (url === '/' && enable) {
      setIsHomepage(true);
      setTransparentBGEnabled(true);
    } else {
      setIsHomepage(false);
      setTransparentBGEnabled(false);
    }
  };

  // if mobile menu is open, disable transparent bg
  // if mobile menu is closed, keep transparent bg if scroll is active
  useEffect(() => {
    if (menuSidebarOpen) {
      setTransparentBGEnabled(false);
    } else if (!scrollIsActive && enable) {
      setTransparentBGEnabled(true);
    }
  }, [menuSidebarOpen]);

  useEffect(() => {
    const updateNavTheme = () => {
      const scrollTop = window.pageYOffset;

      if (
        window.location.pathname !== '/' ||
        menuDrawerIndex ||
        menuSidebarOpen
      ) {
        setTransparentBGEnabled(false);
        return;
      }

      if (scrollTop > 0) {
        setScrollIsActive(true);
        setTransparentBGEnabled(false);
      } else {
        setScrollIsActive(false);
        setTransparentBGEnabled(true);
      }
    };

    window.addEventListener('scroll', updateNavTheme);
    router.events.on('routeChangeComplete', onRountChangeComplete);

    return () => {
      window.removeEventListener('scroll', updateNavTheme);
      router.events.off('routeChangeComplete', onRountChangeComplete);
    };
  }, [menuDrawerIndex, menuSidebarOpen]);

  // sets background state on page load
  useEffect(() => {
    onRountChangeComplete(router?.asPath?.split('?')[0]);
  }, [enable]);

  return {
    isLocked: menuSidebarOpen || scrollIsActive, // prevents event listeners in react components and state updates
    transparentBGEnabled: enable && isHomepage && transparentBGEnabled,
    setTransparentBGEnabled,
  };
};
