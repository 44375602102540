import React from 'react';

export function LogoMobile({ color }) {
  return (
    <svg
      id="logo-mobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 227.87 241.34"
    >
      <defs />
      <g
        id="Layer_1-2"
        style={{
          fill: color,
        }}
      >
        <path
          className="cls-1"
          d="m227.87,61.21c0,15.39-10.11,25.13-22.01,25.13-14.82,0-17.7-14.17-33.23-14.17-8.31,0-18.79,6.88-28.18,17.84v120.89c-.1,12.68,9.94,22.75,22.72,24.35v6.1h-92.03v-6.1c13.36-2.41,21.67-11.77,21.67-24.35l-.54-138.76c0-20.65-7.6-31.6-21.63-31.64l-31.57-.44v76.6c0,28.76,5.97,37.1,17.46,37.1,5.73,0,10.99-3.22,14.11-7.26l3.12,2.71c-5.29,12.34-17.23,23.09-32.08,23.09-20.31,0-34.21-12.07-34.21-44.9V40.05H0v-3.49C12.68,29.03,28.69,15.06,39.71,0l3.36,1.9v33.06c9.56-.27,25.57-.54,31.54-.54h64.46l4.34,3.66v42.59c16.95-25.97,35.74-46.66,57.04-46.66,16.62,0,27.43,10.55,27.43,27.2Z"
        />
      </g>
    </svg>
  );
}

LogoMobile.displayName = 'LogoMobile';
