import { useCallback, useState } from 'react';
import { useCustomer, useSettings } from '@backpackjs/storefront';

export function BackInStockModal({ selectedVariant }) {
  const customer = useCustomer();
  const settings = useSettings();
  const variantId = selectedVariant?.legacyResourceId;

  const [email, setEmail] = useState(customer?.email || '');
  const [success, setSuccess] = useState(false);
  const { heading, headingSuccess, subtext, subtextSuccess, submitText } = {
    ...settings?.product?.backInStock,
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        if (!email || !variantId) return;

        const endpoint = '/api/klaviyo';
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'backInStockSubscribe',
            email,
            variant: variantId,
          }),
        };

        const response = await fetch(endpoint, options);
        const data = await response.json();
        if (!data) {
          throw new Error('No data returned from subscribe');
        }
        setSuccess(true);
        setEmail('');
      } catch (error) {
        console.error(`subscribe error: ${error.message}`);
        throw error;
      }
    },
    [email]
  );

  return (
    <div className="flex flex-col items-center gap-8 text-center">
      <div>
        <h2 className="text-title-h3">{success ? headingSuccess : heading}</h2>
        {subtext && (
          <p className="mt-2">{success ? subtextSuccess : subtext}</p>
        )}
      </div>

      <div>
        <h3 className="text-title-h4">{selectedVariant.product.title}</h3>
        {selectedVariant.title !== 'Default Title' && (
          <p>{selectedVariant.title}</p>
        )}
      </div>

      <form
        className="flex w-full flex-col items-center"
        onSubmit={handleSubmit}
      >
        <input
          className="input-text text-text md:max-w-[30rem]"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email..."
          required
          type="email"
          value={email}
        />

        <button
          aria-label="Notify Me"
          className="btn-primary mt-3 max-md:w-full"
          type="submit"
        >
          {submitText}
        </button>
      </form>
    </div>
  );
}

BackInStockModal.displayName = 'BackInStockModal';
