import { useState } from 'react';
import { A11y, Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { HalfHeroMedia } from './HalfHeroMedia';

export function HalfHeroSlider({ aboveTheFold, slider, slides, videoAlt }) {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const { activeBulletColor, autoplay, delay, effect, pagination } = {
    ...slider,
  };

  return (
    <Swiper
      a11y={{ enabled: true }}
      autoplay={
        autoplay
          ? {
              delay: delay || 5000,
              disableOnInteraction: false,
            }
          : false
      }
      className="h-full w-full"
      effect={effect}
      fadeEffect={{
        crossFade: true,
      }}
      grabCursor
      onSlideChange={(swiper) => setVisibleIndex(swiper.realIndex)}
      modules={[A11y, Autoplay, EffectFade, Pagination]}
      pagination={{
        el: `.swiper-pagination`,
        type: 'bullets',
        clickable: true,
        renderBullet(_, className) {
          return `<span class="${className}" style="background-color: ${activeBulletColor}"></span>`;
        },
      }}
      slidesPerView={1}
    >
      {slides?.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            <HalfHeroMedia
              aboveTheFold={aboveTheFold}
              media={slide}
              videoAlt={videoAlt}
              isActiveSlide={index === visibleIndex}
              isFirstSlide={index === 0}
            />
          </SwiperSlide>
        );
      })}

      <div className={`swiper-pagination ${pagination ? '' : '!hidden'}`} />
    </Swiper>
  );
}

HalfHeroSlider.displayName = 'HalfHeroSlider';
