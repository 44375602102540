import PropTypes from 'prop-types';

import { BackInStockModal } from '../BackInStockModal';
import { Spinner } from '../Spinner';
import { useAddToCart } from '../../hooks';

export function AddToCart({
  addToCartText,
  className,
  isPdp,
  quantity,
  selectedPlan,
  selectedVariant,
}) {
  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut, subtext },
    actions: { handleAddToCart, handleNotifyMe },
  } = useAddToCart({
    addToCartText,
    quantity,
    selectedPlan,
    selectedVariant,
  });

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  return (
    <div>
      <button
        aria-label={buttonText}
        className={`${
          isNotifyMe ? 'btn-inverse-dark' : 'btn-primary'
        } relative w-full ${cursorClass} ${className}`}
        disabled={isSoldOut && !isNotifyMe}
        onClick={() => {
          if (isNotifyMe) {
            handleNotifyMe(
              <BackInStockModal selectedVariant={selectedVariant} />
            );
          } else {
            handleAddToCart();
          }
        }}
        type="button"
      >
        {isAdding ? (
          <Spinner width="20" />
        ) : (
          <p className={`transition ${isLoading ? 'opacity-30' : ''}`}>
            {buttonText}
          </p>
        )}
      </button>

      {isPdp && subtext && (
        <p className="mt-1 text-center text-xs">{subtext}</p>
      )}
    </div>
  );
}

AddToCart.displayName = 'AddToCart';
AddToCart.defaultProps = {
  addToCartText: '',
  className: '',
  isPdp: false,
  quantity: 1,
  selectedVariant: null,
};
AddToCart.propTypes = {
  addToCartText: PropTypes.string,
  className: PropTypes.string,
  isPdp: PropTypes.bool,
  quantity: PropTypes.number,
  selectedVariant: PropTypes.object,
};
