import { useSettings } from '@backpackjs/storefront';

import { Link } from '@snippets';

export function FooterLegal() {
  const settings = useSettings();
  const { links, copyrightNotice } = { ...settings?.footer?.legal };

  return (
    <div
      data-comp={FooterLegal.displayName}
      className="flex flex-col gap-x-4 gap-y-1 px-4 pt-8 text-base text-current md:flex-row md:p-0 md:text-sm"
    >
      <p>
        Copyright &copy; {new Date().getFullYear()} {copyrightNotice}
      </p>

      <ul className="flex flex-wrap gap-x-4 gap-y-1">
        {links?.map(({ link }, index) => {
          return (
            <ul>
              <li key={index} className="flex">
                <p
                  className={`pr-4 ${
                    index === 0 ? 'hidden' : 'block'
                  } md:block`}
                >
                  |
                </p>
                <Link
                  aria-label={link?.text}
                  href={link?.url}
                  newTab={link?.newTab}
                  type={link?.type}
                >
                  <span className="hover-text-underline text-current">
                    {link?.text}
                  </span>
                </Link>
              </li>
            </ul>
          );
        })}
        <li>
          |
          <button id="ot-sdk-btn" className=" pl-4" type="button">
            <p className="hover-text-underline text-current">Cookie Settings</p>
          </button>
        </li>
      </ul>
    </div>
  );
}

FooterLegal.displayName = 'FooterLegal';
