export default {
  label: 'Search',
  name: 'search',
  component: 'group',
  description: 'Search results, autocomplete, suggestions',
  fields: [
    {
      label: 'Search Results (Sidebar)',
      name: 'results',
      component: 'group',
      description:
        'Note: Settings for search results page on filters, sort, pagination, product item, and promotions is shared with collections.\nSee: Storefront Settings > Collection',
      fields: [
        {
          label: 'Product Search Enabled',
          name: 'productsEnabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Collection Search Enabled',
          name: 'collectionsEnabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'No Results Text',
          name: 'noResultsText',
          component: 'text',
        },
      ],
      defaultValue: {
        productsEnabled: true,
        collectionsEnabled: true,
        noResultsText: 'No results found.',
      },
    },
    {
      label: 'Search Autocomplete',
      name: 'autocomplete',
      component: 'group',
      description:
        'Autogenerated list of suggested search terms matching current input',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Limit',
          name: 'limit',
          component: 'number',
        },
      ],
      defaultValue: {
        enabled: true,
        heading: 'Suggestions',
        limit: 5,
      },
    },
    {
      label: 'Search Suggestions',
      name: 'suggestions',
      component: 'group',
      description:
        'Preset list of suggested search terms visible only when there are no results or search input',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Terms',
          name: 'terms',
          component: 'list',
          field: {
            component: 'text',
          },
        },
      ],
      defaultValue: {
        enabled: true,
        heading: 'Top Searches',
        terms: ['T-Shirt', 'Sweatshirt', 'Jacket'],
      },
    },
  ],
};
