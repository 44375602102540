import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { useBodyScrollLock } from '../../hooks';

export function useMenuSidebar() {
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();
  const settings = useSettings();
  const { menuItems } = { ...settings?.header?.menu };

  const [menuSidebarOpen, setMenuSidebarOpen] = useState(false);
  const [nestedSidebarIndex, setNestedSidebarIndex] = useState(null);

  const handleOpenSidebar = useCallback(() => {
    setMenuSidebarOpen(true);
    lockBodyScroll();
  }, []);

  const handleCloseSidebar = useCallback(() => {
    setMenuSidebarOpen(false);
    setNestedSidebarIndex(null);
    unlockBodyScroll();
  }, []);

  const handleNestedSidebar = useCallback((index) => {
    setNestedSidebarIndex(typeof index === 'number' ? index : null);
  }, []);

  return {
    state: {
      menuSidebarOpen,
      nestedSidebarContent:
        typeof nestedSidebarIndex === 'number'
          ? menuItems?.[nestedSidebarIndex] || null
          : null,
    },
    actions: {
      handleOpenSidebar,
      handleCloseSidebar,
      handleNestedSidebar,
    },
  };
}
