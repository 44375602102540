import { useCallback, useEffect, useState } from 'react';
import { store, useCurrency, useCustomer } from '@backpackjs/storefront';
import { nanoid } from 'nanoid';

export function useDataLayerAccount({
  DEBUG,
  generateUserProperties,
  userDataEvent,
  userDataEventTriggered,
}) {
  const customer = useCustomer();
  const currencyCode = useCurrency();

  const accessToken = store.recoil.useRecoilValue(store.state.accessToken);
  const [loggedIn, setLoggedIn] = useState(false);
  const [registered, setRegistered] = useState(false);

  const loggedInEvent = useCallback(({ userProperties }) => {
    const event = {
      event: 'login',
      event_id: nanoid(),
      event_time: new Date().toISOString(),
      user_properties: userProperties,
    };

    window.gtag('event', event.event, event);
    if (DEBUG) console.log(`DataLayer:gtag:${event.event}`, event);
    setLoggedIn(false);
  }, []);

  const registeredEvent = useCallback(({ userProperties }) => {
    const event = {
      event: 'sign_up',
      event_id: nanoid(),
      event_time: new Date().toISOString(),
      user_properties: userProperties,
    };

    window.gtag('event', event.event, event);
    if (DEBUG) console.log(`DataLayer:gtag:${event.event}`, event);
    setRegistered(false);
  }, []);

  const getUserPropertiesAndTriggerLoggedInEvents = useCallback(
    async ({
      customer: _customer,
      currencyCode: _currencyCode,
      accessToken: _accessToken,
    }) => {
      const userProperties = await generateUserProperties({
        customer: _customer,
        accessToken: _accessToken.token,
      });
      loggedInEvent({ userProperties });
      userDataEvent({ currencyCode: _currencyCode, userProperties });
    },
    []
  );

  const getUserPropertiesAndTriggerRegisterEvents = useCallback(
    async ({
      customer: _customer,
      currencyCode: _currencyCode,
      accessToken: _accessToken,
    }) => {
      const userProperties = await generateUserProperties({
        customer: _customer,
        accessToken: _accessToken.token,
      });
      registeredEvent({ userProperties });
      userDataEvent({ currencyCode: _currencyCode, userProperties });
    },
    []
  );

  // Subscribe to PubSub topics for 'login' and 'sign_up' events
  useEffect(() => {
    const customerLoggedIn = PubSub.subscribe(
      'CUSTOMER_LOGGED_IN',
      async () => {
        setLoggedIn(true);
      }
    );
    const customerRegistered = PubSub.subscribe(
      'CUSTOMER_REGISTERED',
      async () => {
        setRegistered(true);
      }
    );
    return () => {
      if (customerLoggedIn) {
        PubSub.unsubscribe(customerLoggedIn);
      }
      if (customerRegistered) {
        PubSub.unsubscribe(customerRegistered);
      }
    };
  }, []);

  // Generate new base data after customer login and customer profile is ready
  // Trigger 'user_data' and 'login' events
  useEffect(() => {
    if (
      !customer ||
      !currencyCode ||
      !accessToken?.token ||
      !loggedIn ||
      !userDataEventTriggered
    )
      return;
    getUserPropertiesAndTriggerLoggedInEvents({
      customer,
      currencyCode,
      accessToken,
    });
  }, [
    !!customer,
    !!currencyCode,
    accessToken?.token,
    loggedIn,
    userDataEventTriggered,
  ]);

  // Generate new base data after customer register and customer profile is ready
  // Trigger 'user_data' and 'sign_up' events
  useEffect(() => {
    if (
      !customer ||
      !currencyCode ||
      !accessToken?.token ||
      !registered ||
      !userDataEventTriggered
    )
      return;
    getUserPropertiesAndTriggerRegisterEvents({
      customer,
      currencyCode,
      accessToken,
    });
  }, [
    !!customer,
    !!currencyCode,
    accessToken?.token,
    registered,
    userDataEventTriggered,
  ]);
}
