import { COLORS } from '../../settings/common';

const image = {
  label: 'Image Settings',
  name: 'image',
  description: 'Image settings, order, aspect ratio',
  component: 'group',
  fields: [
    {
      label: 'Image Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image 1',
      name: 'image1',
      component: 'image',
    },
    {
      label: 'Image Alt 2',
      name: 'alt2',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image 2',
      name: 'image2',
      component: 'image',
    },
    {
      label: 'Media Order (tablet/desktop)',
      name: 'mediaOrderDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: '1' },
        { label: 'Right', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (tablet)',
      name: 'aspectTablet',
      component: 'select',
      options: [
        { label: '3:1.5', value: 'md:aspect-[3/1.5]' },
        { label: '3:2', value: 'md:aspect-[3/2]' },
        { label: '4:3', value: 'md:aspect-[4/3]' },
        { label: '5:4', value: 'md:aspect-[5/4]' },
        { label: '8:7', value: 'md:aspect-[8/7]]' },
        { label: '1:1', value: 'md:aspect-[1/1]' },
        { label: '7:8', value: 'md:aspect-[7/8]' },
        { label: '4:5', value: 'md:aspect-[4/5]' },
        { label: '3:4', value: 'md:aspect-[3/4]' },
        { label: '2:3', value: 'md:aspect-[2/3]' },
      ],
    },
    {
      label: 'Media Aspect Ratio (desktop)',
      name: 'aspectDesktop',
      component: 'select',
      options: [
        { label: '3:1.5', value: 'lg:aspect-[3/1.5]' },
        { label: '3:2', value: 'lg:aspect-[3/2]' },
        { label: '4:3', value: 'lg:aspect-[4/3]' },
        { label: '5:4', value: 'lg:aspect-[5/4]' },
        { label: '8:7', value: 'lg:aspect-[8/7]]' },
        { label: '1:1', value: 'lg:aspect-[1/1]' },
        { label: '7:8', value: 'lg:aspect-[7/8]' },
        { label: '4:5', value: 'lg:aspect-[4/5]' },
        { label: '3:4', value: 'lg:aspect-[3/4]' },
        { label: '2:3', value: 'lg:aspect-[2/3]' },
      ],
    },
    {
      label: 'Fill Empty Space (tablet/desktop)',
      name: 'fill',
      component: 'toggle',
      description:
        'Fill any vertical empty space with media. Applicable only to tablet and desktop',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Media Order (mobile)',
      name: 'mediaOrderMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Top', value: '1' },
        { label: 'Bottom', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (mobile)',
      name: 'aspectMobile',
      component: 'select',
      options: [
        { label: '3:1.5', value: 'max-md:aspect-[3/1.5]' },
        { label: '3:2', value: 'max-md:aspect-[3/2]' },
        { label: '4:3', value: 'max-md:aspect-[4/3]' },
        { label: '5:4', value: 'max-md:aspect-[5/4]' },
        { label: '8:7', value: 'max-md:aspect-[8/7]]' },
        { label: '1:1', value: 'max-md:aspect-[1/1]' },
        { label: '7:8', value: 'max-md:aspect-[7/8]' },
        { label: '4:5', value: 'max-md:aspect-[4/5]' },
        { label: '3:4', value: 'max-md:aspect-[3/4]' },
        { label: '2:3', value: 'max-md:aspect-[2/3]' },
      ],
    },
  ],
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description:
    'Heading, superheading, subtext, buttons, theme color, text color, alignment, content width',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'textarea',
    },
    {
      label: 'Superheading',
      name: 'superheading',
      component: 'text',
    },
    {
      label: 'Subtext',
      name: 'subtext',
      component: 'markdown',
    },
    {
      label: 'Small Body',
      name: 'smallBody',
      component: 'markdown',
    },
    {
      label: 'Buttons',
      name: 'buttons',
      component: 'group-list',
      description: 'Max of two buttons',
      itemProps: {
        label: '{{item.link.text}}',
      },
      validate: {
        maxItems: 2,
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Button Style',
          name: 'style',
          component: 'select',
          options: [
            { label: 'Primary', value: 'btn-primary' },
            { label: 'Secondary', value: 'btn-secondary' },
            { label: 'Inverse Light', value: 'btn-inverse-light' },
            { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            { label: 'Text Link', value: 'btn-text-link' },
          ],
        },
      ],
      defaultItem: {
        link: { text: 'Shop Now', url: '' },
        style: 'btn-primary',
      },
    },
    {
      label: 'Theme Color',
      name: 'themeColor',
      component: 'color',
      defaultValue: '#672C45',
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: COLORS,
    },
    {
      label: 'Content Alignment (tablet/desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
        { label: 'Right', value: 'md:text-right md:items-end' },
      ],
    },
    {
      label: 'Max Content Width (tablet/desktop)',
      name: 'maxWidthDesktop',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'md:max-w-[22rem] lg:max-w-[28rem]' },
        { label: 'Medium', value: 'md:max-w-[30rem] lg:max-w-[38rem]' },
        { label: 'Wide', value: 'md:max-w-[38rem] lg:max-w-[48rem]' },
        { label: 'Full', value: 'md:max-w-full' },
      ],
    },
    {
      label: 'Content Alignment (mobile)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
    },
  ],
  defaultValue: {
    heading: 'Half Hero Heading',
    superheading: '',
    subtext:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    buttons: [
      {
        link: { text: 'Shop Now', url: '' },
        style: 'btn-primary',
      },
    ],
    color: 'var(--text)',
    alignmentDesktop: 'md:text-left md:items-start',
    maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
    alignmentMobile: 'text-left items-start',
  },
};

export const Schema = () => {
  return {
    category: 'Hero',
    label: 'Half Hero Reveal',
    key: 'half-hero-reveal',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/half-hero-reveal-preview.png?v=1701209820',
    fields: [
      image,
      content,
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Background color, above the fold, full width, full bleed, vertical padding',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Above The Fold',
            name: 'aboveTheFold',
            component: 'toggle',
            description: `Sets the heading as H1 and image load as priority`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          bgColor: 'var(--background)',
          aboveTheFold: false,
          fullWidth: true,
          fullBleed: true,
          verticalPadding: false,
        },
      },
    ],
  };
};
