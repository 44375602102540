import { COLORS, TEXT_COLORS } from '../common';

export const rotatingBar = {
  label: 'Rotating bar',
  name: 'rotatingBar',
  component: 'group',
  fields: [
    {
      label: 'Enabled',
      name: 'enabled',
      description: 'Enabling rotating bar will replace promobar',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Autohide',
      name: 'autohide',
      component: 'toggle',
      description: 'Hides promobar after scrolling away from top of the page',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: TEXT_COLORS.mobile,
      defaultValue: 'text-[var(--white)]',
    },
    {
      label: 'Background Color',
      name: 'bgColor',
      component: 'select',
      options: COLORS,
      defaultValue: 'var(--accent1)',
    },
    {
      label: 'Scrolling Speed',
      name: 'scrollingSpeed',
      description:
        'scrolling speed in seconds. The higher the number, the slower the scroll movement',
      component: 'number',
      default: 30,
    },
    {
      label: 'Messages',
      name: 'messages',
      component: 'group-list',
      itemProps: {
        label: '{{item.message}}',
      },
      fields: [
        {
          label: 'Message',
          name: 'message',
          component: 'textarea',
        },
        {
          label: 'Link (optional)',
          name: 'link',
          component: 'link',
          description: 'Link appears at end of message',
        },
      ],
      defaultItem: {
        message: 'Free shipping on orders over $100. Shop Now',
        link: { url: '/', text: '' },
      },
    },
  ],
};
