import { useEffect } from 'react';
import { useRouter } from 'next/router';

export function useScrollRestoration() {
  const { asPath } = useRouter();
  const pathname = asPath.split('?')[0];

  useEffect(() => {
    const scrollHistory = window.sessionStorage.getItem(
      `__next_scroll_${window.history?.state?.key}`
    );
    if (scrollHistory && process.env.NODE_ENV !== 'development') {
      const coordinates = JSON.parse(scrollHistory);
      if (coordinates?.y) {
        window.scrollTo(0, coordinates.y);
      }
    }
  }, [pathname]);
}
