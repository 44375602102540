import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Spinner } from '@snippets';
import { ImageTile } from './ImageTile';
import { Schema } from './ImageTiles.schema';

export function ImageTiles({ cms }) {
  const { content, heading, section, tiles } = cms;

  const [swiper, setSwiper] = useState(null);

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const fullBleedClass = section?.fullBleed ? '' : 'px-contained py-4 md:py-6';
  const swiperDisplayClass = section?.swiperOnMobile ? 'lg:hidden' : 'hidden';
  const gridDisplayClass = section?.swiperOnMobile ? 'hidden lg:grid' : 'grid';
  const gridGapClass = section?.fullBleed ? '' : 'gap-x-5 gap-y-5';

  return (
    <div data-comp={ImageTiles.displayName} className={`${fullBleedClass}`}>
      <div className={`mx-auto ${maxWidthClass}`}>
        {heading && (
          <h2 className="text-title-h2 mb-6 px-4 text-center md:mb-10">
            {heading}
          </h2>
        )}

        {tiles?.length > 0 && (
          <>
            {/* mobile/tablet */}
            <div
              className={`relative ${swiperDisplayClass} ${
                !swiper ? 'min-h-[25rem]' : ''
              }`}
            >
              <Swiper
                grabCursor
                onSwiper={setSwiper}
                slidesOffsetAfter={0}
                slidesOffsetBefore={0}
                slidesPerView={1.4}
                spaceBetween={16}
                breakpoints={{
                  768: {
                    slidesPerView: tiles.length < 3 ? 1.4 : 2.4,
                    slidesOffsetBefore: 32,
                    slidesOffsetAfter: 32,
                    spaceBetween: 20,
                  },
                }}
              >
                {swiper &&
                  tiles.slice(0, 3).map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <ImageTile content={content} item={item} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>

              {!swiper && (
                <Spinner
                  width="32"
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                />
              )}
            </div>

            {/* desktop */}
            <div
              className={`${gridDisplayClass} ${gridGapClass} ${
                tiles.length < 3 ? 'md:grid-cols-2' : 'md:grid-cols-3'
              }`}
            >
              {tiles.slice(0, 3).map((item, index) => {
                return (
                  <div className="relative" key={index}>
                    <ImageTile content={content} item={item} />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ImageTiles.displayName = 'ImageTiles';
ImageTiles.Schema = Schema;
