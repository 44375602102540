import { useCallback } from 'react';
import { Image, Markdown } from '@snippets';
import { useGlobalContext } from '@contexts';
import { Schema } from './PdpModal.schema';

export function PdpModal({ cms }) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const handleClick = useCallback(() => {
    if (!cms?.buttonText) return;
    openModal(
      <div className="flex flex-col">
        <h2 className="text-title-h3 mb-6 text-center">{cms?.heading}</h2>
        <div className="flex h-full w-full">
          <Image
            alt={cms?.alt}
            src={cms?.image?.src}
            width="500px"
            height="500px"
            className="flex h-full w-full object-contain"
          />
        </div>
        {cms?.subtext && (
          <div className="mt-6 [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <Markdown>{cms?.subtext}</Markdown>
          </div>
        )}
      </div>
    );
  }, [cms?.buttonText]);

  return (
    <button
      data-comp={PdpModal.displayName}
      onClick={handleClick}
      className="text-underline text-xs"
      type="button"
    >
      {cms?.buttonText}
    </button>
  );
}

PdpModal.displayName = 'PdpModal';
PdpModal.Schema = Schema;
