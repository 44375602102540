import { COLORS } from '@settings/common';

export function Schema() {
  return {
    category: 'Slider',
    label: 'Products Slider',
    key: 'products-slider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/products-slider-preview.jpg?v=1675730335',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Products Slider Heading',
      },
      {
        label: 'Products',
        name: 'products',
        component: 'group-list',
        itemProps: {
          label: '{{item.product.handle}}',
        },
        fields: [
          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
          },
          {
            label: 'Promo Tile',
            name: 'promoTile',
            component: 'group',
            description: 'Use Promo Tile settigns to overide a product tile.',
            fields: [
              {
                label: 'Enable',
                name: 'enabled',
                component: 'toggle',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
              },
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
              {
                label: 'Link Style',
                name: 'linkStyle',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: [
                  { label: 'Clickable Tile', value: 'clickableTile' },
                  { label: 'CTA Button', value: 'button' },
                ],
                defaultValue: 'clickableTile',
              },
              {
                label: 'Button Style',
                name: 'buttonStyle',
                component: 'select',
                description: 'Only applies if link style is set to CTA Button',
                options: [
                  { label: 'Primary', value: 'btn-primary' },
                  { label: 'Secondary', value: 'btn-secondary' },
                  {
                    label: 'Inverse Light',
                    value: 'btn-inverse-light',
                  },
                  { label: 'Inverse Dark', value: 'btn-inverse-dark' },
                  { label: 'Text Link', value: 'btn-text-link' },
                ],
              },
              {
                label: 'Background Settings',
                name: 'background',
                component: 'group',
                description: 'Background color, image, video, dark overlay',
                fields: [
                  {
                    label: 'Background Color',
                    name: 'bgColor',
                    component: 'select',
                    options: COLORS,
                  },
                  {
                    label: 'Image Alt',
                    name: 'alt',
                    component: 'text',
                    description: 'Brief description of image',
                  },
                  {
                    label: 'Image',
                    name: 'image',
                    component: 'image',
                    description: 'Overrides background color',
                  },
                  {
                    label: 'Video URL',
                    name: 'videoSrc',
                    component: 'text',
                    description: 'Overrides image. Autoplays once in view',
                  },
                  {
                    label: 'Video Poster Image',
                    name: 'videoPoster',
                    component: 'image',
                    description: 'First frame of video while video loads',
                  },
                  {
                    label: 'Dark Overlay',
                    name: 'darkOverlay',
                    component: 'toggle',
                    description: 'Adds 20% opacity black overlay over media',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                  },
                ],
              },
              {
                label: 'Text Settings',
                name: 'text',
                component: 'group',
                description: 'Heading, subtext, text color',
                fields: [
                  {
                    label: 'Text Position',
                    name: 'position',
                    component: 'select',
                    options: [
                      {
                        label: 'Top',
                        value: 'top-10 left-1/2 -translate-x-1/2',
                      },
                      {
                        label: 'Bottom',
                        value: 'bottom-10 left-1/2 -translate-x-1/2',
                      },
                      {
                        label: 'Center',
                        value:
                          'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                      },
                    ],
                    defaultValue:
                      'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                  },
                  {
                    label: 'Max Content Width',
                    name: 'maxContentWidth',
                    component: 'select',
                    options: [
                      { label: 'Narrow', value: 'max-w-[10rem]' },
                      { label: 'Medium', value: 'max-w-[15rem]' },
                      { label: 'Wide', value: 'max-w-[20rem]' },
                      { label: 'Full', value: 'max-w-full' },
                    ],
                  },
                  {
                    label: 'Image Alt',
                    name: 'alt',
                    component: 'text',
                    description: 'Brief description of image',
                  },
                  {
                    label: 'Image',
                    name: 'image',
                    component: 'image',
                  },
                  {
                    label: 'Heading',
                    name: 'heading',
                    component: 'text',
                  },
                  {
                    label: 'Heading Style',
                    name: 'headingStyle',
                    component: 'radio-group',
                    direction: 'horizontal',
                    variant: 'radio',
                    options: [
                      { label: 'Main', value: 'text-title-h4' },
                      { label: 'Alt', value: 'text-alt-h4' },
                    ],
                    defaultValue: 'text-title-h4',
                  },
                  {
                    label: 'Subtext',
                    name: 'subtext',
                    component: 'textarea',
                  },
                  {
                    label: 'Text Color',
                    name: 'textColor',
                    component: 'select',
                    options: COLORS,
                  },
                ],
              },
            ],
          },
        ],
        defaultValue: [
          { handle: '' },
          { handle: '' },
          { handle: '' },
          { handle: '' },
        ],
      },
      {
        label: 'Footer Button',
        name: 'button',
        component: 'link',
      },
      {
        label: 'Product Item Settings',
        name: 'productItem',
        component: 'group',
        description: 'Star rating, color variant selector, quick shop',
        fields: [
          {
            label: 'Enable Star Rating',
            name: 'enabledStarRating',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Color Variant Selector',
            name: 'enabledColorSelector',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },

          {
            label: 'Enable Color Name On Hover',
            name: 'enabledColorNameOnHover',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Quick Shop',
            name: 'enabledQuickShop',
            component: 'toggle',
            description:
              'Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          enabledStarRating: false,
          enabledColorSelector: false,
          enabledColorNameOnHover: false,
          enabledQuickShop: false,
        },
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        component: 'group',
        description: 'Slider style, slides per view',
        fields: [
          {
            label: 'Slider Style',
            name: 'sliderStyle',
            component: 'select',
            description:
              'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
            options: [
              { label: 'Contained', value: 'contained' },
              { label: 'Contained w/ Loop', value: 'containedWithLoop' },
              { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
              {
                label: 'Full Bleed, Centered w/ Loop & Gradient',
                value: 'fullBleedWithGradient',
              },
            ],
          },
          {
            label: 'Slides Per View (desktop)',
            name: 'slidesPerViewDesktop',
            component: 'number',
          },
          {
            label: 'Slides Per View (tablet)',
            name: 'slidesPerViewTablet',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
          {
            label: 'Slides Per View (mobile)',
            name: 'slidesPerViewMobile',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
        ],
        defaultValue: {
          sliderStyle: 'contained',
          slidesPerViewDesktop: 4,
          slidesPerViewTablet: 3.4,
          slidesPerViewMobile: 1.4,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Button style, full width',
        fields: [
          {
            label: 'Button Style',
            name: 'buttonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
              { label: 'Text Link', value: 'btn-text-link' },
            ],
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          buttonStyle: 'btn-primary',
          fullWidth: false,
        },
      },
    ],
  };
}
