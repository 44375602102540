import { useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Link } from '@snippets';

export function RotatingBar({
  promobarDisabled,
  promobarHidden,
  setPromobarHidden,
}) {
  const settings = useSettings();
  const { rotatingBar } = { ...settings?.header };
  const { autohide, bgColor, color, enabled, messages, scrollingSpeed } = {
    ...rotatingBar,
  };

  useEffect(() => {
    const setPromobarVisibility = () => {
      if (document.body.style.position === 'fixed') return;
      setPromobarHidden(window.scrollY > 48);
    };

    if (!autohide) {
      setPromobarHidden(false);
      window.removeEventListener('scroll', setPromobarVisibility);
      return undefined;
    }

    window.addEventListener('scroll', setPromobarVisibility);
    return () => {
      window.removeEventListener('scroll', setPromobarVisibility);
    };
  }, [autohide]);

  if (!enabled || messages?.length < 1) return null;

  return (
    <div
      data-comp={RotatingBar.displayName}
      className={`relative flex w-full items-center overflow-hidden overflow-x-hidden transition-[height] ease-out ${
        promobarHidden || promobarDisabled
          ? 'h-0 duration-[50ms]'
          : 'h-[var(--promobar-height)] duration-300'
      }`}
      style={{ background: bgColor, backgroundImage: bgColor }}
    >
      <div className="flex w-full">
        {[...Array(4).keys()].map((_, index) => {
          return (
            <div
              key={index}
              className="relative flex shrink-0 overflow-hidden"
              style={{
                animation: `scroll ${scrollingSpeed || '10'}s infinite linear`,
              }}
            >
              {messages?.map(({ message, link }, index) => {
                return (
                  <div
                    className={`${color} mx-5 flex leading-none`}
                    key={index}
                  >
                    {message && (
                      <p className="select-none font-helvetica text-[13px] md:text-sm">
                        {message}
                      </p>
                    )}
                    {link?.text && (
                      <Link
                        aria-label={message}
                        className="text-link-sm  ml-1 select-none text-[13px] md:text-sm"
                        draggable={false}
                        href={link.url}
                        newTab={link.newTab}
                        type={link.type}
                      >
                        {link.text}
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

RotatingBar.displayName = 'RotatingBar';
