import { Image, Link } from '@snippets';
import { useRouter } from 'next/router';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  handleMenuHoverOut,
  menuDrawerContent,
  transparentBGEnabled,
}) {
  const { imageLinks, links, mainLink } = { ...menuDrawerContent };
  const hasContent = imageLinks?.length > 0 || links?.length > 0;
  const { pathname } = useRouter();
  return (
    <div
      data-comp={MenuDrawer.displayName}
      className={`absolute left-0 top-[100%] hidden w-full origin-top border-border bg-background transition duration-200 lg:block ${
        hasContent ? 'scale-y-100 border-b' : 'scale-y-0'
      }`}
      onMouseEnter={handleMenuDrawerStayOpen}
      onMouseLeave={handleMenuHoverOut}
    >
      {hasContent && (
        <div
          className={`grid grid-cols-[24rem_1fr] gap-5 px-[152px] py-8 md:py-12 xl:px-[168px] ${
            transparentBGEnabled
              ? 'px-[177px] xl:px-[193px]'
              : pathname === '/'
              ? 'px-[177px] xl:px-[193px]'
              : ''
          }`}
        >
          <div>
            <ul className="flex flex-col gap-2">
              {links?.map(({ link }, index) => {
                return (
                  <li key={index}>
                    <Link
                      aria-hidden={!hasContent}
                      aria-label={link?.text}
                      className="hover-text-underline"
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleMenuDrawerClose}
                      tabIndex={hasContent ? '0' : '-1'}
                      type={link?.type}
                    >
                      {link?.text}
                    </Link>
                  </li>
                );
              })}
            </ul>

            {mainLink?.text && (
              <Link
                aria-hidden={!hasContent}
                aria-label={mainLink.text}
                className="btn-primary mt-5"
                href={mainLink.url}
                newTab={mainLink.newTab}
                onClick={handleMenuDrawerClose}
                tabIndex={hasContent ? '0' : '-1'}
                type={mainLink.type}
              >
                {mainLink.text}
              </Link>
            )}
          </div>

          <ul className="grid grid-cols-2 gap-5">
            {imageLinks?.map(({ alt, caption, image, link }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-hidden={!hasContent}
                    aria-label={caption}
                    href={link?.url}
                    newTab={link?.newTab}
                    onClick={handleMenuDrawerClose}
                    tabIndex={hasContent ? '0' : '-1'}
                    type={link?.type}
                  >
                    <div className="relative aspect-[16/9] w-full overflow-hidden bg-offWhite">
                      {image?.src && (
                        <Image
                          alt={alt}
                          className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                          crop="center"
                          height={Math.floor(400 / (16 / 9))}
                          src={image.src}
                          width="400"
                        />
                      )}
                    </div>

                    <p className="mt-3 text-sm">{caption}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';
