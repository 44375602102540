import { COLORS, SWATCHES } from './common';

export default {
  label: 'Product',
  name: 'product',
  component: 'group',
  description:
    'Add to cart, back in stock, badges, color swatches, quantity selector, reviews',
  fields: [
    {
      label: 'Add To Cart',
      name: 'addToCart',
      component: 'group',
      description: 'Add to cart, sold out, presale text, subtext',
      fields: [
        {
          label: 'Add To Cart Text',
          name: 'addToCartText',
          component: 'text',
        },
        {
          label: 'Sold Out Text',
          name: 'soldOutText',
          component: 'text',
        },
        {
          label: 'Preorder Text',
          name: 'preorderText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
          description: 'Text below the Add To Cart button',
        },
      ],
      defaultValue: {
        addToCartText: 'Add To Cart',
        soldOutText: 'Sold Out',
        preorderText: 'Preorder',
        subtext: '',
      },
    },
    {
      label: 'Back In Stock',
      name: 'backInStock',
      component: 'group',
      description: 'Enable notifications, notify me text, modal texts',
      fields: [
        {
          label: 'Enable Notifications',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Notify Me Text',
          name: 'notifyMeText',
          component: 'text',
        },
        {
          label: 'Modal Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Modal Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Modal Heading - Success',
          name: 'headingSuccess',
          component: 'text',
        },
        {
          label: 'Modal Text - Success',
          name: 'subtextSuccess',
          component: 'text',
        },
        {
          label: 'Modal Submit Text',
          name: 'submitText',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: false,
        notifyMeText: 'Notify Me When Available',
        heading: 'Notify Me When Available',
        subtext: `Enter your email below and we'll notify you when this product is back in stock.`,
        submitText: 'Submit',
      },
    },
    {
      label: 'Badges',
      name: 'badges',
      component: 'group',
      description: 'Badge colors',
      fields: [
        {
          label: 'Badge Colors',
          name: 'badgeColors',
          component: 'group-list',
          description:
            'Note: product badges are set up via Shopify tags using the format "badge::Some Value"',
          itemProps: {
            label: '{{item.tag}}',
          },
          fields: [
            {
              label: 'Tag Value',
              name: 'tag',
              component: 'text',
              description:
                'Letter casing must be same as tag value in Shopify, e.g. "New", "Sale"',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Image',
              name: 'image',
              component: 'group',
              description: 'Renders Image instead of text above',
              fields: [
                {
                  label: 'Image - Background',
                  name: 'imageBackground',
                  component: 'group',
                  fields: [
                    {
                      label: 'Enable Endless Rotation Animation',
                      name: 'enabledRotationAnimation',
                      component: 'toggle',
                      toggleLabels: {
                        true: 'On',
                        false: 'Off',
                      },
                    },
                    {
                      label: 'Animation Speed',
                      name: 'animationSpeed',
                      component: 'number',
                      defaultValue: 10,
                    },
                    {
                      label: 'Alt',
                      name: 'alt',
                      component: 'text',
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                    },
                  ],
                },
                {
                  label: 'Image - Stacked',
                  name: 'imageStacked',
                  component: 'group',
                  fields: [
                    {
                      label: 'Enable Endless Rotation Animation',
                      name: 'enabledRotationAnimation',
                      component: 'toggle',
                      toggleLabels: {
                        true: 'On',
                        false: 'Off',
                      },
                    },
                    {
                      label: 'Animation Speed',
                      name: 'animationSpeed',
                      component: 'number',
                      defaultValue: 10,
                    },
                    {
                      label: 'Alt',
                      name: 'alt',
                      component: 'text',
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                    },
                  ],
                },
              ],
            },
          ],
          defaultItem: {
            bgColor: 'var(--black)',
            textColor: 'var(--white)',
          },
          defaultValue: [
            {
              tag: 'Draft',
              bgColor: 'var(--mediumGray)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Best Seller',
              bgColor: 'var(--black)',
              textColor: 'var(--white)',
            },
            {
              tag: 'New',
              bgColor: 'var(--secondary)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Sale',
              bgColor: 'var(--primary)',
              textColor: 'var(--white)',
            },
          ],
        },
      ],
    },
    {
      label: 'Icons',
      name: 'icons',
      component: 'group',
      fields: [
        {
          label: 'Icons',
          name: 'list',
          description:
            'Order of icons here will determine order of icons on product page',
          component: 'group-list',
          itemProps: {
            label: '{{item.label}}',
          },
          defaultItem: {
            tag: 'New Icon',
            label: 'New Icon',
          },
          fields: [
            {
              label: 'Tag',
              name: 'tag',
              component: 'text',
              description:
                'Tag name added to product in Shopify, e.g. "Lifetime Warranty" for "icon::warranty" in Shopfiy',
            },
            {
              label: 'Label',
              name: 'label',
              component: 'text',
            },
            {
              label: 'Icon SVG',
              name: 'icon',
              component: 'image',
            },
          ],
        },
      ],
    },
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      description: 'Color swatches',
      fields: [
        {
          label: 'Color Swatches',
          name: 'swatches',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          defaultItem: {
            name: 'New Color',
          },
          fields: [
            {
              label: 'Color Name',
              name: 'name',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
              colors: SWATCHES,
            },
            {
              name: 'image',
              label: 'Image',
              component: 'image',
              description:
                'If provided, image will overlay the color.\nEnsure image is no more than 2KB in size',
            },
          ],
          defaultValue: [
            {
              name: 'Black',
              color: '#000000',
            },
            {
              name: 'White',
              color: '#FFFFFF',
            },
          ],
        },
      ],
    },
    {
      label: 'Quantity Selector',
      name: 'quantitySelector',
      component: 'group',
      description: 'Enable',
      fields: [
        {
          label: 'Enable Quantity Selector',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabled: false,
      },
    },
    {
      label: 'Reviews',
      name: 'reviews',
      component: 'group',
      description: 'Enable star rating',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabledStarRating: true,
      },
    },
  ],
};
