import React from 'react';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider';
import { HalfHeroContent } from '@sections/HalfHero/HalfHeroContent';
import { Schema } from './HalfHeroReveal.schema';

export function HalfHeroReveal({ cms }) {
  const { image, content, section } = cms;
  const {
    aspectDesktop,
    aspectMobile,
    aspectTablet,
    fill,
    image1,
    image2,
    mediaOrderDesktop,
    mediaOrderMobile,
  } = { ...image };

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const fillClass = fill
    ? 'h-full'
    : `md:before:float-left ${aspectTablet} ${aspectDesktop}`;
  const mediaOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-2' : 'order-1'
  } ${mediaOrderDesktop === '2' ? 'md:order-2' : 'md:order-1'}`;
  const contentOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-1' : 'order-2'
  } ${mediaOrderDesktop === '2' ? 'md:order-1' : 'md:order-2'}`;
  const mediaBgColorClass =
    section?.bgColor === 'var(--background)' ||
    section?.bgColor === 'var(--white)'
      ? 'bg-offWhite'
      : '';

  return (
    <div
      data-comp={HalfHeroReveal.displayName}
      className={`${section?.fullBleed ? '' : 'px-contained'} ${
        section?.verticalPadding ? 'py-contained' : ''
      }`}
      id={cms?.clientId || cms?.cmsId}
      style={{ background: section?.bgColor }}
    >
      <style>
        {`
            [data-comp-id="${
              cms?.clientId || cms?.cmsId
            }"] .__rcs-handle-button {
              border-color: transparent;
              background-color: rgba(239, 204, 184, 0.9) !important;
              width: 36px !important;
              height: 36px !important;
              gap: 6px !important;
            }
            [data-comp-id="${
              cms?.clientId || cms?.cmsId
            }"] .__rcs-handle-arrow {
              color: ${content?.themeColor};
              border-top: 6px solid transparent !important;
              border-right: 9px solid !important;
              border-bottom: 6px solid transparent !important;
            }
            [data-comp-id="${cms?.clientId || cms?.cmsId}"] .__rcs-handle-line {
              background-color: ${content?.themeColor} !important;
              width: 5px !important;
            }
          `}
      </style>

      <div
        className={`relative mx-auto grid grid-cols-1 items-center md:grid-cols-2 ${maxWidthContainerClass}`}
      >
        <div
          className={`relative flex w-full justify-center max-md:before:float-left ${aspectMobile} ${aspectTablet} ${aspectDesktop} ${fillClass} ${mediaOrderClasses} ${mediaBgColorClass}`}
        >
          <div className="flex h-full justify-center">
            <ReactCompareSlider
              style={{
                border: `5px solid ${content?.themeColor}`,
                borderRadius: '30px',
                width: '100%',
                height: '100%',
              }}
              itemOne={
                <ReactCompareSliderImage
                  src={
                    image1?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/before.webp'
                  }
                  srcSet={
                    image1?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/before.webp'
                  }
                  alt={image1?.alt || 'Image one'}
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={
                    image2?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/after.webp'
                  }
                  srcSet={
                    image2?.src ||
                    'https://img-comparison-slider.sneas.io/demo/images/after.webp'
                  }
                  alt={image2?.alt || 'Image two'}
                />
              }
            />
          </div>
        </div>

        <div
          className={`flex w-full justify-center md:before:float-left ${contentOrderClasses}`}
        >
          <HalfHeroContent
            aboveTheFold={section?.aboveTheFold}
            content={content}
            section={section}
          />
        </div>
      </div>
    </div>
  );
}

HalfHeroReveal.displayName = 'HalfHeroReveal';
HalfHeroReveal.Schema = Schema;
