import { COLORS } from '../../settings/common';

export const Schema = () => {
  return {
    label: 'Made With',
    key: 'made-with',
    category: 'Text',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/made-with-preview.png?v=1701209060',
    fields: [
      {
        label: 'Tab Settings',
        name: 'tab',
        component: 'group',
        fields: [
          {
            label: 'Tabs',
            name: 'tabs',
            component: 'group-list',
            itemProps: {
              label: '{{item.name}}',
            },
            validate: {
              maxItems: 2,
            },
            fields: [
              {
                label: 'Name',
                name: 'name',
                component: 'text',
              },
              {
                label: 'Text',
                name: 'text',
                component: 'markdown',
              },
              {
                label: 'Images',
                name: 'images',
                component: 'group-list',
                itemProps: {
                  label: '{{item.heading}}',
                },
                fields: [
                  {
                    label: 'Heading',
                    name: 'heading',
                    component: 'text',
                  },
                  {
                    label: 'Alt',
                    name: 'alt',
                    component: 'text',
                  },
                  {
                    label: 'Image',
                    name: 'image',
                    component: 'image',
                  },
                ],
                defaultItem: {
                  heading: 'Root Biomic Ferment TM',
                  image: {
                    src: 'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/ingredient-fpo.png?v=1701201413',
                  },
                },
              },
              {
                label: 'Modal Settings',
                name: 'modal',
                component: 'group',
                description:
                  'Trigger button settings, Modal Heading, Modal Subtext',
                fields: [
                  {
                    label: 'Button Text',
                    name: 'buttonText',
                    component: 'text',
                  },
                  {
                    label: 'Button Text Color',
                    name: 'buttonTextColor',
                    component: 'select',
                    options: COLORS,
                  },
                  {
                    label: 'Button Style',
                    name: 'buttonStyle',
                    component: 'select',
                    options: [
                      { label: 'Primary', value: 'btn-primary' },
                      { label: 'Secondary', value: 'btn-secondary' },
                      { label: 'Inverse Light', value: 'btn-inverse-light' },
                      { label: 'Inverse Dark', value: 'btn-inverse-dark' },
                      { label: 'Text Link', value: 'btn-text-link' },
                    ],
                  },
                  {
                    label: 'Heading',
                    name: 'heading',
                    component: 'text',
                  },
                  {
                    label: 'Subtext',
                    name: 'subtext',
                    component: 'markdown',
                  },
                ],
              },
            ],
            defaultValue: [
              {
                name: 'Made With',
                text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                modal: {
                  buttonText: 'Full Ingredient List',
                  buttonTextColor: 'var(--primary)',
                  buttonStyle: 'btn-text-link',
                  heading: 'Full Ingredient List Heading',
                  subtext:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                },
                images: [
                  {
                    heading: 'Root Biomic Ferment TM',
                    image: {
                      src: 'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/ingredient-fpo.png?v=1701201413',
                    },
                  },
                  {
                    heading: 'Vitamin C',
                    image: {
                      src: 'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/ingredient-fpo.png?v=1701201413',
                    },
                  },
                  {
                    heading: 'Hemisqualane',
                    image: {
                      src: 'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/ingredient-fpo.png?v=1701201413',
                    },
                  },
                ],
              },
              {
                name: 'Made Without',
                text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                modal: {
                  buttonText: 'Full Ingredient List',
                  buttonTextColor: 'var(--primary)',
                  buttonStyle: 'btn-text-link',
                  heading: 'Full Ingredient List Heading',
                  subtext:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                },
                images: [
                  {
                    heading: 'Root Biomic Ferment TM 2',
                    image: {
                      src: 'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/ingredient-fpo.png?v=1701201413',
                    },
                  },
                  {
                    heading: 'Vitamin C 2',
                    image: {
                      src: 'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/ingredient-fpo.png?v=1701201413',
                    },
                  },
                  {
                    heading: 'Hemisqualane 2',
                    image: {
                      src: 'https://cdn.shopify.com/s/files/1/0496/4578/1156/files/ingredient-fpo.png?v=1701201413',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'content alignment',
        component: 'group',
        fields: [
          {
            label: 'Content Alignment (mobile)',
            name: 'alignmentMobile',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'max-lg:text-left max-lg:justify-start' },
              {
                label: 'Center',
                value: 'max-lg:text-center max-lg:justify-center',
              },
              { label: 'Right', value: 'max-lg:text-right max-lg:justify-end' },
            ],
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Text color, background color',
        fields: [
          {
            label: 'Text Color',
            name: 'color',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
        ],
        defaultValue: {
          text: 'var(--accent1)',
          bgColor: 'var(--lighter-gray)',
        },
      },
    ],
  };
};
