import { useCallback, useEffect, useState } from 'react';

export function useDataLayerSubscribe({ DEBUG, userDataEventTriggered }) {
  const [subscribedEmail, setSubscribedEmail] = useState('');
  const [subscribedPhone, setSubscribedPhone] = useState('');

  const subscribeEmailEvent = useCallback(({ email }) => {
    if (!email) return;
    const event = {
      event: 'dl_subscribe',
      lead_type: 'email',
      user_properties: { customer_email: email },
    };

    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
    window.ElevarDataLayer.push(event);
    if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
  }, []);

  const subscribePhoneEvent = useCallback(({ phone }) => {
    if (!phone) return;
    const event = {
      event: 'dl_subscribe',
      lead_type: 'phone',
      user_properties: { customer_phone: phone },
    };

    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
    window.ElevarDataLayer.push(event);
    if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
  }, []);

  // Subscribe to PubSub topic for 'dl_subscribe' event
  useEffect(() => {
    const subscribedWithEmail = PubSub.subscribe(
      'SUBSCRIBE_EMAIL',
      async (event, email) => {
        setSubscribedEmail(email);
      }
    );
    const subscribedWithPhone = PubSub.subscribe(
      'SUBSCRIBE_PHONE',
      async (event, phone) => {
        setSubscribedPhone(phone);
      }
    );
    return () => {
      if (subscribedWithEmail) {
        PubSub.unsubscribe(subscribedWithEmail);
      }
      if (subscribedWithPhone) {
        PubSub.unsubscribe(subscribedWithPhone);
      }
    };
  }, []);

  // Trigger 'dl_subscribe' event after email submission
  useEffect(() => {
    if (!subscribedEmail || !userDataEventTriggered) return;
    subscribeEmailEvent({ email: subscribedEmail });
  }, [subscribedEmail, userDataEventTriggered]);

  // Trigger 'dl_subscribe' event after phone submission
  useEffect(() => {
    if (!subscribedPhone || !userDataEventTriggered) return;
    subscribePhoneEvent({ phone: subscribedPhone });
  }, [subscribedPhone, userDataEventTriggered]);
}
