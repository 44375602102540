import { useState, useEffect, useRef, createRef } from 'react';
import { Image } from '@snippets';

export function MadeWithImages({ activeTab, tabs }) {
  const [largestHeight, setLargestHeight] = useState(null);

  const tabsRefs = useRef(
    tabs?.reduce((carry, ref, index) => {
      return {
        ...carry,
        [`${ref.name} ${index}`]: createRef(),
      };
    }, {}) || null
  );

  useEffect(() => {
    const onResize = () => {
      let currLargestHeight = 0;

      Object.keys(tabsRefs.current).forEach((key) => {
        const tab = tabsRefs?.current?.[key];
        if (!tab) return;
        const firstEl = tab?.current?.querySelector('.tabs-inner');
        const height = firstEl?.offsetHeight;
        if (height > currLargestHeight) {
          currLargestHeight = height;
        }
      });
      setLargestHeight(currLargestHeight);
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div>
      <ul
        style={{
          height: largestHeight,
        }}
      >
        {tabs?.map((tab, index) => {
          const { images, name } = { ...tab };
          return (
            <ul
              className={`grid grid-cols-3 gap-5 text-center ${
                activeTab?.name === tab?.name
                  ? 'opacity-1 absolute inset-0'
                  : 'pointer-events-none opacity-0'
              }`}
              key={index}
              ref={tabsRefs?.current[`${name} ${index}`]}
            >
              {images?.map((_image, index) => {
                const { heading, alt, image } = { ..._image };

                if (!image?.src) return null;

                return (
                  <li key={index}>
                    <div className="tabs-inner">
                      <div className="relative aspect-[3/5] overflow-hidden rounded-[20px] border-2  border-[var(--accent1)]">
                        <Image alt={alt} fill sizes="33vw" src={image.src} />
                      </div>
                      <h3 className="mt-2 px-2 font-helvetica text-[10px] font-semibold leading-none md:px-4 md:text-[20px] lg:text-base xl:text-[20px]">
                        {heading}
                      </h3>
                    </div>
                  </li>
                );
              })}
            </ul>
          );
        })}
      </ul>
    </div>
  );
}

MadeWithImages.displayName = 'MadeWithImages';
