import React from 'react';

export function Logo({ color }) {
  return (
    <svg
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 710.76 620.84"
    >
      <defs />
      <g id="Layer_1-2" style={{ fill: color }}>
        <path
          className="cls-1"
          d="m18.6,361.45l-.46-116.18c0-17.32-6.35-26.5-18.14-26.5v-5.09h53.97l3.63,3.06v35.67c14.21-21.74,29.93-39.07,47.77-39.07,13.91,0,22.98,8.83,22.98,22.76,0,12.91-8.46,21.06-18.44,21.06-12.39,0-14.81-11.89-27.81-11.89-6.95,0-15.72,5.78-23.58,14.95v101.23c-.09,10.61,8.31,19.04,19.01,20.38v5.1H.46v-5.1c11.19-2.04,18.14-9.85,18.14-20.38"
        />
        <path
          className="cls-1"
          d="m207.98,384.89c18.44,0,26.6-31.93,26.6-79.49,0-52.31-9.98-86.96-31.14-86.96-19.65,0-28.42,32.95-28.42,82.21s10.58,84.25,32.96,84.25m-75.28-81.19c0-55.38,26.61-91.38,72.56-91.38s71.65,34.99,71.65,89-26,89.68-71.35,89.68-72.87-34.31-72.87-87.3"
        />
        <path
          className="cls-1"
          d="m370.34,384.89c18.44,0,26.6-31.93,26.6-79.49,0-52.31-9.98-86.96-31.14-86.96-19.65,0-28.42,32.95-28.42,82.21s10.58,84.25,32.96,84.25m-75.28-81.19c0-55.38,26.61-91.38,72.56-91.38s71.65,34.99,71.65,89-26,89.68-71.35,89.68-72.87-34.31-72.87-87.3"
        />
        <path
          className="cls-1"
          d="m461.52,334.28v-110.41h-14.51v-4.41c16.02-9.51,36.28-27.17,50.19-46.2l4.23,2.38v41.78c12.09-.34,32.35-.68,39.91-.68v8.49c-6.05,0-27.21-1.02-39.91-1.36v96.82c0,36.34,7.56,46.88,22.07,46.88,7.25,0,13.91-4.08,17.84-9.17l3.93,3.4c-6.65,15.63-21.77,29.21-40.51,29.21-25.7,0-43.23-15.29-43.23-56.73"
        />
        <path
          className="cls-1"
          d="m488.54,609.63v-57.75h3.93c10.88,38.73,34.77,62.84,53.51,62.84,12.39,0,19.96-10.19,19.96-25.82,0-39.07-74.98-55.03-74.98-103.95,0-25.14,19.95-42.81,52-42.81,15.72,0,39,5.43,47.77,11.21l-4.84,49.94h-3.93c-11.19-38.73-27.81-55.03-41.42-55.03-9.07,0-15.11,7.81-15.11,20.04,0,32.95,74.98,50.96,74.98,103.95,0,26.84-21.17,48.58-56.84,48.58-23.58,0-46.26-5.44-55.02-11.21"
        />
        <path
          className="cls-1"
          d="m627.01,564.11v-110.41h-14.51v-4.41c16.02-9.51,36.28-27.17,50.19-46.2l4.23,2.38v41.78c12.09-.34,32.35-.68,39.91-.68v8.49c-6.05,0-27.21-1.02-39.91-1.36v96.82c0,36.34,7.56,46.88,22.07,46.88,7.25,0,13.91-4.08,17.84-9.17l3.93,3.4c-6.65,15.63-21.77,29.21-40.51,29.21-25.7,0-43.23-15.29-43.23-56.73"
        />
        <path
          className="cls-1"
          d="m419.63,592.48v-101.57c0-10.87-4.53-18.01-18.14-20.04v-5.1l54.42-21.06,3.63,3.06v144.71c0,10.87,7.56,18.68,19.05,20.38v5.1h-77.09v-5.1c11.19-2.04,18.14-9.85,18.14-20.38"
        />
        <path
          className="cls-1"
          d="m416.03,404.89c0-11.29,9.22-20.44,20.58-20.44s20.58,9.15,20.58,20.44-9.22,20.44-20.58,20.44-20.58-9.15-20.58-20.44"
        />
        <path
          className="cls-1"
          d="m130.73,67.7c-7.04-.06-13.7,3.94-17.56,9.54l-.56,75.2c-.06,7.23,4.93,12.47,12.56,13.67l-.02,3.39-51.28-.48.03-3.39c7.46-1.29,12.11-6.43,12.17-13.44l.92-123.3c.06-7.45-2.93-11.54-11.96-13.66l.03-3.38L111.13,0l1.8,2.05-.53,70.68c7.12-11.22,19.64-19.24,31.3-19.13,18.91.17,30.27,12.7,29.87,39.8l-.66,60.52c.35,6.78,5.35,11.56,12.57,12.76l-.03,3.38-51.26-.48.02-3.38c7.45-1.29,12.11-6.44,12.17-13.43l.6-53.3c.38-24.16-4.99-31.66-16.26-31.77"
        />
        <path
          className="cls-1"
          d="m251.16,96.83c-.2-27.56-6.56-38.23-15.2-38.31-9.65-.09-15.96,10.46-17.58,38l32.78.3Zm-61.26,16.82c.3-39.74,21.56-59.42,47.7-59.17,22.71.21,39.07,18.43,38.87,44.62l-1.43,2.47-56.9-.53-.05,6.55c-.26,34.55,13.89,50.04,31.19,50.2,9.64.09,19.14-5.47,24.63-13.1l2.99,2.51c-8.16,16.19-21.1,26.23-39.4,26.06-25.54-.23-47.87-24.38-47.61-59.61"
        />
        <path
          className="cls-1"
          d="m12.72,133.44V60.29H3.11v-2.92c10.61-6.3,24.04-18,33.25-30.61l2.8,1.58v27.68c8.01-.22,21.43-.45,26.44-.45v5.63c-4.01,0-18.03-.67-26.44-.9v64.15c0,24.08,5.01,31.06,14.62,31.06,4.81,0,9.21-2.7,11.82-6.08l2.6,2.25c-4.41,10.35-14.42,19.36-26.84,19.36-17.03,0-28.64-10.13-28.64-37.58"
        />
      </g>
    </svg>
  );
}

Logo.displayName = 'Logo';
